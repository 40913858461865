<template>
    <div class="app-container" @click="visible = false;">
        <el-card class="mb15">
            <el-row>
                <el-col :span="20" class="mb0">
                    <el-button type="primary" @click="handleAdd">新增奖评</el-button>
                </el-col>
                <el-col :span="4" class="mr0 mb0">
                    <div class="pull-right">
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true"
                                label-width="68px">
                                <el-row class="">
                                    <el-col :span="24" class=" mr0"
                                        v-if="role.includes(0) || role.includes(1) || role.includes(2) || role.includes(3)">
                                        <el-form-item label="学校" prop="schoolId" class="w-100">
                                            <el-select clearable class="w-100" @change="changeSchool"
                                                v-model="queryParams.schoolId" placeholder="请选择学校">
                                                <el-option v-for="item in schoolList" :key="item.schoolId"
                                                    :label="item.schoolName" :value="item.schoolId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="">
                                        <el-form-item label="班级" prop="classId" class="w-100">
                                            <el-select clearable class="w-100"
                                                v-model="queryParams.classId" placeholder="请选择班级">
                                                <el-option v-for="item in classList" :key="item.classId"
                                                    :label="item.className" :value="item.classId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <div style="margin:0 auto;">
                                        <el-button @click="getList()" type="primary">查找</el-button>
                                    </div>
                                </el-row>
                            </el-form>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <!-- <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
                <el-row class="">
                    <el-col :span="8" class=" mr0"
                        v-if="role.includes(0) || role.includes(1) || role.includes(2) || role.includes(3)">
                        <el-form-item label="学校" prop="schoolId">
                            <el-select style="width: 100%;" clearable @change="changeSchool" v-model="queryParams.schoolId"
                                placeholder="请选择学校">
                                <el-option v-for="item in schoolList" :key="item.schoolId" :label="item.schoolName"
                                    :value="item.schoolId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="">
                        <el-form-item label="班级" prop="classId">
                            <el-select style="width: 100%;" clearable @change="changeClass" v-model="queryParams.classId"
                                placeholder="请选择班级">
                                <el-option v-for="item in classList" :key="item.classId" :label="item.className"
                                    :value="item.classId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="role.includes(0) || role.includes(1) || role.includes(2) || role.includes(3) ? 8 : 16"
                        class="">
                        <el-button style="width:100px" class="pull-right" type="primary" @click="getList()">查找</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider style="position: relative;" v-if="isSxShow">
                <img @click="isSxShow = false;" style="transform: translateY(-100%);" class="sxShowcss"
                    src="../../assets/img/arrow-up.png" alt="">
            </el-divider> -->
            <div class="bre9e" style="position: relative;">
                <!-- <img @click="isSxShow = true;" v-if="!isSxShow" style="transform: translateY(-36px);" class="sxShowcss"
                    src="../../assets/img/arrow-down.png" alt=""> -->
                <el-table header-cell-class-name="bgf2" highlight-current-row class="sssstt"
                    :height="'calc(100vh - ' + (isSxShow ? '255' : '255') + 'px)'" v-loading="loading" :data="dataList">
                    <el-table-column align="center" type="index" width="60" prop="studentNo" label="序号">
                    </el-table-column>
                    <el-table-column align="center" width="300" label="奖评类别" prop="awardContent">
                        <template #default="scope">
                            <div :title="scope.row.awardContent" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.awardContent }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="150" label="学校名称" prop="schoolName">
                        <template #default="scope">
                            <div :title="scope.row.schoolName" class="text-one cursor">
                                {{ scope.row.schoolName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="150" label="星级数" prop="starNum">
                        <template #default="scope">
                            <el-rate disabled v-model="scope.row.starNum" />
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="500" label="班级" prop="gradeClassIsm">
                        <template #default="scope">
                            <div :title="scope.row.gradeClassIsmTxt" class="text-one">
                                {{ scope.row.gradeClassIsmTxt }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column align="center" fixed="right" width="150" label="编辑"
                        class-name="small-padding fixed-width">
                        <template #default="scope">
                            <div style="display: flex;" class="disnone">
                                <el-button link icon="Edit" type="primary" @click="handleUpdate(scope.row)">修改
                                </el-button>
                                <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                    icon-color="#626AEF" title="确定删除该奖评" @confirm="handleDelete(scope.row, scope.$index)">
                                    <template #reference>
                                        <el-button link icon="Delete" type="danger">删除
                                        </el-button>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <!-- <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize" @pagination="getList" /> -->
        </el-card>
        <el-dialog :title="'云本资源'" v-model="readBook" width="60%">
            <div class="fl-warp" style="display: flex;">
                <div v-for="item in readBookList" class="csbbb">
                    <img style="width: 100px;margin-top: 10px;"
                        :src="this.$store.state.zdy.imgUrl + '/book/' + item.id + '/img/cover.png_original'" alt=""
                        class="image">
                    <div style="width: 100px;text-align: center;" class="text-one mb15">{{ item.bookName }}</div>
                </div>
            </div>

        </el-dialog>
    </div>
</template>

<script>
import { bookList } from "@/api/adminservice/public";
import { timestampToTime } from "@/utils/date";
import { queryCustoms, stopCustoms, delCustoms, queryTreeCustomOrg } from "@/api/adminservice/custom";
import { queryCustomQun } from "@/api/adminservice/qun";
import { download } from "@/api/adminservice/public";
import { classList, schoolUserAuthCondition, schoolListAuth, schoolList, zhktQueryDict, awardList, awardDelete } from "@/api/adminservice/classOrganizaMain";

export default {
    name: "commentSet",
    components: {
    },
    data() {
        return {
            visible: false,
            classList: [],
            isSxShow: true,
            classIsmList: [],
            token: this.$storage.get("token"),
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            readBook: false,
            readBookList: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                schoolId: '',
                classId: ''
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            orgList: [],
            schoolList: [],
            customList: [],
            role: '',
        };
    },
    created() {
        this.role = this.$storage.get("role")
        schoolUserAuthCondition().then(res => {
            if (!this.role.includes(0) && !this.role.includes(1) && !this.role.includes(2) && !this.role.includes(3)) {
                this.classList = res.data.data.classList;
            }
        })
        this.getZhktQueryDict();
        // this.getList();
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
            if (this.$route.query.customId) {
                this.queryParams.customId = this.$route.query.customId
                this.changeCustom()
            } else {
            }
        }
    },
    mounted() {
        if (this.$route.query.customId) {
            this.queryParams.customId = this.$route.query.customId
            this.changeCustom()
        } else {
        }
        // this.getList();

        this.getBookList();

    },
    methods: {
        changeSchool() {
            this.classList = [];
            this.queryParams.classId = '';

            if (this.queryParams.schoolId) {
                classList({ customId: this.$storage.get('customId'), schoolId: this.queryParams.schoolId }).then(res => {
                    this.classList = res.data.data;
                    // this.getList();
                });
            }


        },
        getZhktQueryDict() {
            schoolListAuth({ customId: this.queryParams.customId ? this.queryParams.customId : this.$storage.get('customId'), adminUserId: ((this.role.includes(3) || this.role.includes(2)) && !this.role.includes(1)) ? JSON.parse(this.$storage.get('userInfo')).userId : '' }).then(res => {
                this.schoolList = res.data.data;
            });
            // schoolList({ customId: this.$storage.get('customId') }).then(res => {
            //     this.schoolList = res.data.data;
            // });
            zhktQueryDict({ code: 'grade_class_ism' }).then(res => {
                this.classIsmList = res.data.data;
            })
            this.customList = []
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
            });
        },
        goDetail(row) {
            this.$router.push({
                path: "/commentSetDetail",
                query: {
                    awardId: row.awardId,
                }
            });
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            // if (file.url) {
            //     delLessonFile({ fileUrl: file.url })
            // }
            // if (file.response) {
            //     delLessonFile({ fileUrl: file.response.data })
            // }
        },
        handleChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('导入成功')
                    this.getList()
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg)
                    } else {
                        this.$message.error('导入失败')
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('导入失败')
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgName
                        };
                        parentIds = [item.orgName];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgName);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgName
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            // console.log(parentIds)
            return parentIds

        },
        changeCustom() {
            queryTreeCustomOrg({ customId: this.queryParams.customId }).then(res => {

                this.orgList = res.data.data;
                // console.log(4343,this.findParentNode('f66df71c0ee9425d920132c40b7bba2e'))

                queryCustomQun({ customId: this.queryParams.customId }).then(res => {
                    res.data.data.forEach(e => {
                        console.log(e.orgId)

                        console.log(this.findParentNode(e.orgId))

                        e.orgName = this.findParentNode(e.orgId).join(' -> ')
                        // console.log(434,this.findParentNode(e.orgId))
                    })
                    this.dataList = res.data.data;

                })
            })
        },
        readbookIds(row) {
            this.readBookList = [];
            this.bookList.forEach(e => {
                row.bookIds.forEach(j => {
                    if (e.id == j) {
                        this.readBookList.push(e)
                    }
                })
            })
            this.readBook = true;
        },
        getBookList() {
            bookList().then(res => {
                this.bookList = res.data.data;
            });
        },
        tgMethod(e) {
            this.queryParams.customName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;
            if ((!this.role.includes(0) && !this.role.includes(1)) && (this.role.includes(2) || this.role.includes(3))) {
                if (!this.queryParams.schoolId) {
                    this.$message.warning("请选择学校")
                    return;
                }
            }
            if ((!this.role.includes(0) && !this.role.includes(1) && !this.role.includes(2) && !this.role.includes(3)) && (this.role.includes(4) || this.role.includes(5))) {
                if (!this.queryParams.classId) {
                    this.$message.warning("请选择班级")
                    return;
                }
            }
            awardList({ customId: this.$storage.get('customId') }).then(res => {
                if (this.queryParams.schoolId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.schoolId == this.queryParams.schoolId
                    })
                }

                this.dataList = res.data.data;
                this.dataList.forEach(e => {
                    var classIdttt = []
                    var gragradeClassIsmTxt = [];
                    e.classList.forEach(j => {
                        gragradeClassIsmTxt.push(j.className)
                        classIdttt.push(j.classId)
                    })
                    e.gradeClassIsmTxt = gragradeClassIsmTxt.toString(',')
                    e.classIdtttTxt = classIdttt.toString(',')
                })
                if (this.queryParams.schoolId !== '') {
                    this.dataList = this.dataList.filter(itemA => {
                        return itemA.schoolId.indexOf(this.queryParams.schoolId) != -1
                    })
                }
                if (this.queryParams.classId !== '') {
                    this.dataList = this.dataList.filter(itemA => {
                        return itemA.classIdtttTxt.indexOf(this.queryParams.classId) != -1
                    })
                }
                // if (this.queryParams.gradeClassIsm !== '') {
                //     this.dataList = this.dataList.filter(itemA => {
                //         return itemA.gradeClassIsmList.indexOf(this.queryParams.gradeClassIsm) != -1
                //     })
                // }
                // if (!this.$route.query.customId) {
                //     this.queryParams.customId = res.data.data[0].customId;
                //     this.changeCustom()
                // } else {
                // }
            });
        },

        // 停用
        handleStop(row) {
            stopCustoms({ customId: row.customId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停用成功");
                    this.getList();
                } else {
                    this.$message.error("停用失败");
                }
            })
        },
        /** 删除按钮操作 */
        handleDelete(row, index) {
            awardDelete({ awardId: row.awardId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.dataList.splice(index, 1)
                    // this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },

        /** 导出按钮操作 */
        handleExport() {
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.did);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/commentSetAdd",
                query: {
                    // customId: this.queryParams.customId,
                }
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/commentSetAdd",
                query: {
                    awardId: row.awardId,
                }
            });
            // this.taskdata = row;
            // this.open = true;
        }
    }
};
</script>
<style scoped>
.disnone /deep/ .el-upload-list {
    display: none;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}</style>