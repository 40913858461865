<template>
    <div class="app-container" @click="visible = false;">
        <el-card class="mb15">
            <el-row>
                <el-col :span="16" class="mb0">
                    <el-button type="primary" @click="handleAdd">新建表单</el-button>
                    <el-button type="primary" @click="hadleDz">铺设点阵</el-button>
                    <el-button type="primary" @click="hadlePs">设计表单</el-button>
                    <el-popover placement="bottom-start" :width="125" trigger="hover" :min-width="'auto'">
                        <div @click="handleXzPs" class="pxbutton">
                            合成点阵文件
                        </div>
                        <div @click="handleXz" class="pxbutton">
                            空白文件
                        </div>
                        <template #reference>
                            <el-button type="primary">文件下载</el-button>
                        </template>
                    </el-popover>
                    <el-popover placement="bottom-start" :width="125" trigger="hover" :min-width="'auto'">
                        <div @click="handleRelease" class="pxbutton">
                            发布
                        </div>
                        <div @click="handleStop" class="pxbutton">
                            取消发布
                        </div>
                        <template #reference>
                            <el-button type="primary">发布表单</el-button>
                        </template>
                    </el-popover>
                    <!-- <el-button type="primary" @click="handleXzPs">下载合成点阵文件</el-button>
                    <el-button type="primary" @click="handleXz">下载空白点阵文件</el-button>
                    <el-button type="primary" @click="handleRelease">发布表单</el-button>
                    <el-button type="primary" @click="handleStop">取消发布表单</el-button> -->
                </el-col>
                <el-col :span="8" class="mr0 mb0">
                    <div class="pull-right">
                        <el-button type="primary" @click="getList()">刷新</el-button>
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true"
                                label-width="68px" @submit.native.prevent="handleQuery">
                                <el-row class="">
                                    <el-col :span="24" class="" v-if="role.includes(0)">
                                        <el-form-item label="归属客户" class="w-100">
                                            <el-select clearable class="w-100" @change="changeCustom"
                                                v-model="queryParams.customId" placeholder="请选择">
                                                <el-option v-for="item in customList" :key="item.customId"
                                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                                    :value="item.customId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="">
                                        <el-form-item label="场景名称" class="w-100">
                                            <el-select clearable class="w-100" v-model="queryParams.sceneId"
                                                placeholder="请选择">
                                                <el-option v-for="item in sceneList" :key="item.sceneId"
                                                    :label="item.customAbbreviation + ' | ' + item.sceneName"
                                                    :value="item.sceneId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="">
                                        <el-form-item label="流程状态" class="w-100">
                                            <el-select clearable class="w-100" v-model="queryParams.processStatus"
                                                placeholder="请选择">
                                                <el-option v-for="item in processStatusList" :key="item.value"
                                                    :label="item.name" :value="item.value" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="">
                                        <el-form-item label="铺设状态" class="w-100">
                                            <el-select clearable class="w-100" v-model="queryParams.status"
                                                placeholder="请选择">
                                                <el-option v-for="item in statusList" :key="item.value" :label="item.name"
                                                    :value="item.value" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="">
                                        <el-form-item label="业务类型" class="w-100">
                                            <el-select clearable class="w-100" v-model="queryParams.businessType"
                                                placeholder="请选择">
                                                <el-option label="普通表单" :value="0" />
                                                <el-option label="群笔记表单" :value="2" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                </el-row>
                                <el-row>
                                    <div style="margin:0 auto;">
                                        <el-button @click="getList()" type="primary">查找</el-button>
                                    </div>
                                </el-row>
                            </el-form>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                </el-col>
                <!-- <el-col :span="isSuperAuth ? 2 : 2" class="mr0 mb0">
                    <el-form-item class="pull-right">
                        <el-icon :color="isSxShow ? '#409EFC' : ''" class="cursor" @click="isSxShow = !isSxShow"
                            type="primary" :size="20">
                            <Filter />
                        </el-icon>
                    </el-form-item>
                </el-col> -->
            </el-row>
        </el-card>
        <el-card>
            <!-- <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                @submit.native.prevent="handleQuery">
                <el-row class="">
                    <el-col :span="8" class="">
                        <el-form-item label="流程状态">
                            <el-select clearable @change="getList" v-model="queryParams.processStatus" placeholder="请选择">
                                <el-option v-for="item in processStatusList" :key="item.value" :label="item.name"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="">
                        <el-form-item label="铺设状态">
                            <el-select clearable @change="getList" v-model="queryParams.status" placeholder="请选择">
                                <el-option v-for="item in statusList" :key="item.value" :label="item.name"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="">
                        <el-form-item label="业务类型">
                            <el-select clearable @change="getList" v-model="queryParams.businessType" placeholder="请选择">
                                <el-option label="普通表单" :value="0" />
                                <el-option label="群笔记表单" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="">
                    <el-col :span="8" class="" v-if="role.includes(0)">
                        <el-form-item label="归属客户">
                            <el-select clearable @change="changeCustom" v-model="queryParams.customId" placeholder="请选择">
                                <el-option v-for="item in customList" :key="item.customId"
                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="">
                        <el-form-item label="场景">
                            <el-select clearable @change="getList" v-model="queryParams.sceneId" placeholder="请选择">
                                <el-option v-for="item in sceneList" :key="item.sceneId"
                                    :label="item.customAbbreviation + ' | ' + item.sceneName" :value="item.sceneId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider style="position: relative;" v-if="isSxShow">
                <img @click="isSxShow = false;" style="transform: translateY(-100%);" class="sxShowcss"
                    src="../../assets/img/arrow-up.png" alt="">
            </el-divider> -->
            <div class="bre9e" style="position: relative;">
                <!-- <img @click="isSxShow = true;" v-if="!isSxShow" style="transform: translateY(-36px);" class="sxShowcss"
                    src="../../assets/img/arrow-down.png" alt=""> -->
                <el-table header-cell-class-name="bgf2" show-overflow-tooltip @current-change="handleCurrentChange" class=""
                    :height="isSxShow ? 'calc(100vh - 255px)' : 'calc(100vh - 270px)'" v-loading="loading" :data="dataList"
                    highlight-current-row>
                    <!-- <el-table-column align="center" type="selection" width="55" /> -->
                    <el-table-column align="center" type="index" label="序号" width="60" />
                    <el-table-column align="center" width="90" label="铺设状态" prop="status">
                        <template #default="scope">
                            <div v-if="scope.row.status == 0">未铺设</div>
                            <div v-if="scope.row.status == 1">铺设中</div>
                            <div v-if="scope.row.status == 2">铺设成功</div>
                            <div v-if="scope.row.status == 3">铺设失败</div>
                            <!-- <div v-if="scope.row.status == 4">发布</div>
                        <div v-if="scope.row.status == 5">停用</div> -->
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="90" label="流程状态" prop="processStatus">
                        <template #default="scope">
                            <div v-if="scope.row.processStatus == 0">未发布</div>
                            <div v-if="scope.row.processStatus == 1">已发布</div>
                            <div v-if="scope.row.processStatus == 2">已停用</div>
                            <!-- <div v-if="scope.row.status == 4">发布</div>
                        <div v-if="scope.row.status == 5">停用</div> -->
                        </template>
                    </el-table-column>
                    <!-- <el-table-column align="center" fixed="left" width="170" label="编号" prop="formNo" />
                <el-table-column align="center" width="200" label="文件名称" prop="fileName" /> -->
                    <el-table-column align="center" width="350" label="表单名称" prop="formName">
                        <template #default="scope">
                            <div :title="scope.row.formName" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.formName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="60" label="页数" prop="pageCount" />
                    <el-table-column align="center" width="100" label="业务类型" prop="businessType">
                        <template #default="scope">
                            <div v-if="scope.row.businessType || scope.row.businessType == 0">{{ scope.row.businessType == 0
                                ?
                                '普通表单' : scope.row.businessType == 1 ? '明信片' : '群笔记表单' }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="120" label="分类" prop="formType" />
                    <el-table-column align="center" width="60" label="设计" prop="isDesign">
                        <template #default="scope">
                            <div>{{ scope.row.isDesign == 0 ? '否' : '是' }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="80" label="记录数" prop="recordCount" />
                    <el-table-column align="center" width="350" label="场景" prop="sceneName" sortable />
                    <el-table-column align="center" width="220" v-if="role.includes(0)" label="归属客户" prop="customName"
                        sortable>
                        <template #default="scope">
                            <div :title="scope.row.customName" class="text-one">
                                {{ scope.row.customName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="140" label="创建时间" prop="createTime" sortable>
                        <template #default="scope">
                            <div>{{ timestampToTime(scope.row.createTime) }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="140" label="发布时间" prop="releaseTime">
                        <template #default="scope">
                            <div>{{ timestampToTime(scope.row.releaseTime) }}</div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column align="center" width="140" label="停用时间" prop="stopTime">
                    <template #default="scope">
                        <div>{{ timestampToTime(scope.row.stopTime) }}</div>
                    </template>
                </el-table-column> -->
                    <!-- <el-table-column align="center" width="150" label="点阵地址" prop="startPageAddress" /> -->
                    <el-table-column align="center" fixed="right" width="150" label="编辑"
                        class-name="small-padding fixed-width">
                        <template #default="scope">
                            <div style="display: flex;" class="disnone">
                                <el-button link icon="Edit" type="primary" @click="handleUpdate(scope.row)">修改
                                </el-button>
                                <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                    icon-color="#626AEF" title="确定删除该表单" @confirm="handleDelete(scope.row, scope.$index)">
                                    <template #reference>
                                        <el-button link icon="Delete" type="danger">删除
                                        </el-button>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <!-- <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize" @pagination="getList" /> -->
        </el-card>
    </div>
</template>

<script>

import {
    exportData
} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { queryCustoms } from "@/api/adminservice/custom";
import { formQuery, deleteForm, formRelease, formStop, print, formPrintTqlBlank } from "@/api/adminservice/form"
import { download } from "@/api/adminservice/public"
import { queryScene } from "@/api/adminservice/scene"

export default {
    name: "scenarioMain",
    components: {
    },
    data() {
        return {
            visible: false,
            customList: [],
            processStatusList: [
                { name: '未发布', value: 0 },
                { name: '已发布', value: 1 },
                { name: '已停用', value: 2 },
            ],
            statusList: [
                { name: '未铺设', value: 0 },
                { name: '铺设中', value: 1 },
                { name: '铺设成功', value: 2 },
                { name: '铺设失败', value: 3 },
                // { name: '发布', value: 4 },
                // { name: '停用', value: 5 },
            ],
            sceneList: [],
            timestampToTime,
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            isSxShow: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                processStatus: '',
                businessType: '',
                screenName: '',
                status: '',
                formType: '',
                sceneId: '',
                isDesign: '',
                customId: '',
                createTime: [],
                releaseTime: []
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            selectObj: [],
            role: '',
        };
    },
    created() {
        this.role = this.$storage.get("role")
        this.getList();
        this.getSceneList()
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        hadleDz() {
            if (!this.selectObj || !this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.selectObj.processStatus == 1 || this.selectObj.processStatus == 2) {
                this.$message.error("发布和停用的数据不能铺设");
                return;
            }
            if (this.role.includes(1)) {
                var st = Number(this.$storage.get('010StTime'))
                var end = Number(this.$storage.get('010EdTime'))
                var nT = new Date().getTime();
                if (nT < st || nT > end) {
                    this.$message.error('当前客户没有权限')
                    return
                }
                if (end < (nT + 2592000000)) {
                    this.$message.warning('当前客户权限快过期')
                }
            }
            print({ formId: this.selectObj.formId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("铺设成功");
                    this.getList();
                } else {
                    this.$message.error("铺设失败");
                }
            })
        },
        handleCurrentChange(val) {
            console.log(val)
            this.selectObj = val;
        },
        handleSelectionChange(val) {
            console.log(val)
            this.selectList = val;
        },
        tgMethod(e) {
            this.queryParams.screenName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        getSceneList() {
            this.customList = []
            queryScene({ customId: this.$storage.get('customId') }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.sceneType != 1;
                })
                this.sceneList = res.data.data;
            });
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customType.includes('010');
                })
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
                this.customList.forEach((e, i) => {
                    if (e.customName == '南京孜博汇信息科技有限公司') {
                        this.customList.splice(i, 1)
                        this.customList.unshift(e)
                    }
                })
            });
        },
        changeCustom() {
            this.queryParams.sceneId = '';
            queryScene({ customId: this.queryParams.customId ? this.queryParams.customId : this.$storage.get('customId') }).then(res => {
                if (this.queryParams.customId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.customId.toString().indexOf(this.queryParams.customId) != -1
                    })
                }
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.sceneType != 1;
                })
                this.sceneList = res.data.data;
            });
            // this.getList()
        },
        /** 查询基础：基数数据列表 */
        getList() {
            console.log(this.queryParams.createTime)
            console.log(this.queryParams.releaseTime)
            this.dataList = [];
            this.loading = false;

            formQuery({ customId: this.$storage.get('customId') }).then(res => {
                if (this.queryParams.processStatus !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.processStatus == this.queryParams.processStatus
                    })
                }
                if (this.queryParams.businessType !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.businessType == this.queryParams.businessType
                    })
                }
                if (this.queryParams.screenName !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.formNo.indexOf(this.queryParams.screenName) != -1 || itemA.fileName.indexOf(this.queryParams.screenName) != -1 || itemA.formName.indexOf(this.queryParams.screenName) != -1
                    })
                }
                if (this.queryParams.status !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.status.toString().indexOf(this.queryParams.status) != -1
                    })
                }
                if (this.queryParams.formType !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.formType.toString().indexOf(this.queryParams.formType) != -1
                    })
                }
                if (this.queryParams.sceneId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.sceneId.toString().indexOf(this.queryParams.sceneId) != -1
                    })
                }
                if (this.queryParams.isDesign !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.isDesign.toString().indexOf(this.queryParams.isDesign) != -1
                    })
                }
                if (this.queryParams.customId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.customId.toString().indexOf(this.queryParams.customId) != -1
                    })
                }
                if (this.queryParams.createTime && this.queryParams.createTime.length != 0) {
                    console.log(1)
                    res.data.data = res.data.data.filter(itemA => {
                        let currTime = itemA.createTime
                        let res = ((currTime - this.queryParams.createTime[0]) > 0 && (currTime - this.queryParams.createTime[1]) < 0);
                        return res
                    })
                }
                if (this.queryParams.releaseTime && this.queryParams.releaseTime.length != 0) {
                    console.log(1)
                    res.data.data = res.data.data.filter(itemA => {
                        let currTime = itemA.releaseTime
                        let res = ((currTime - this.queryParams.releaseTime[0]) > 0 && (currTime - this.queryParams.releaseTime[1]) < 0);
                        return res
                    })
                }
                console.log(2)

                this.dataList = res.data.data;
                this.dataList.sort(function (a, b) {
                    if (a.createTime > b.createTime) { return -1; }
                    if (a.createTime < b.createTime) { return 1; }
                    return 0;
                })
            });
        },

        // 停用
        handleStop(row) {
            if (!this.selectObj || !this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.selectObj.processStatus != 1) {
                this.$message.error("请选择已发布的数据");
                return;
            }
            formStop({ formId: this.selectObj.formId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停用成功");
                    this.dataList.forEach(e=>{
                        if(e.formId == this.selectObj.formId){
                            e.processStatus = 2;
                        }
                    })
                    // this.getList();
                } else {
                    this.$message.error("停用失败");
                }
            })
        },
        handleRelease() {
            if (!this.selectObj || !this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.selectObj.status == 0) {
                this.$message.error("未铺设的数据不能发布");
                return;
            }
            formRelease({ formId: this.selectObj.formId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("发布成功");
                    this.dataList.forEach(e=>{
                        if(e.formId == this.selectObj.formId){
                            e.processStatus = 1;
                        }
                    })
                    // this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        handleXz() {
            if (!this.selectObj || !this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.selectObj.isBlank == 2) {
                this.$message.error("腾千里空白点阵文件正在铺设，请稍后刷新重试");
                return;
            }
            if (this.selectObj.isBlank != 1) {
                if (this.selectObj.dotType == 1) {
                    formPrintTqlBlank({ formId: this.selectObj.formId }).then(res => {
                        this.$message.error("当前腾千里文件无空白点阵文件，正在铺设空白文件中，请稍后刷新重试");
                        this.selectObj.isBlank = 2;
                    })
                    return;
                } else {
                    this.$message.error("当前文件无空白点阵文件");
                    return;
                }
            }
            var fileName = this.selectObj.formName + '.pdf'
            download(this.selectObj.formUrl + '/emptyDot.pdf', this.selectObj.formName + '空白点阵文件')
        },
        handleXzPs() {
            console.log(this.selectObj)
            if (!this.selectObj || !this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.selectObj.processStatus == 1 || this.selectObj.status == 2) {

            } else {
                this.$message.error("请选择铺设成功或发布的数据");
                return;
            }
            var fileName = this.selectObj.formName + '_dot.pdf'
            console.log(fileName, this.selectObj.formUrl + '/dot.pdf')
            download(this.selectObj.formUrl + '/dot.pdf', fileName)
        },
        hadlePs() {
            if (!this.selectObj || !this.selectObj.formId) {
                this.$message.error("请选择数据");
                return;
            }
            if (this.selectObj.isDesign == 0) {
                this.$message.error("当前表单没有设计权限")
                return;
            }
            console.log(this.selectObj)
            let routeUrl = this.$router.resolve({
                path: '/ZBDesigner',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { userId: JSON.parse(this.$storage.get('userInfo')).userId, formId: this.selectObj.formId }
            })
            window.open(routeUrl.href, '_blank')

            // var url = "http://192.168.101.116:8080/?userId=" + JSON.parse(this.$storage.get('userInfo')).userId + "&formId=" + this.selectObj.formId
            // window.open(url)
        },
        /** 删除按钮操作 */
        handleDelete(row,index) {
            if (row.recordCount != 0) {
                this.$message.error("请先删除表单记录");
            } else {
                deleteForm({ formId: row.formId }).then(res => {
                    if (res.data.code == 1) {
                        this.$message.success("删除成功");
                        this.dataList.splice(index, 1)
                        // this.getList();
                    } else {
                        this.$message.error("删除失败");
                    }
                })
            }

        },
        goDetail(row) {
            this.$router.push({
                path: "/formDetail",
                query: {
                    formId: row.formId,
                }
            });
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        /** 新增按钮操作 */
        handleAdd() {
            if (this.role.includes(1)) {
                var st = Number(this.$storage.get('010StTime'))
                var end = Number(this.$storage.get('010EdTime'))
                var nT = new Date().getTime();
                if (nT < st || nT > end) {
                    this.$message.error('当前客户没有权限')
                    return
                }
                if (end < (nT + 2592000000)) {
                    this.$message.warning('当前客户权限快过期')
                }
            }
            this.$router.push({
                path: "/formMainAdd",
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/formMainAdd",
                query: {
                    formId: row.formId,
                }
            });
        }
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.pxbutton {
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}
</style>