<template>
    <div class="app-container pt0Im pl0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>活动管理</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!activityId">新增活动</el-breadcrumb-item>
                <el-breadcrumb-item v-if="activityId">编辑活动</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="活动名称" prop="activityName">
                            <el-input v-model="form.activityName" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="活动地点" prop="activityAddress">
                            <el-input v-model="form.activityAddress" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="活动标题" prop="activityTitle">
                            <el-input v-model="form.activityTitle" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="活动副标题" prop="activitySubTitle">
                            <el-input v-model="form.activitySubTitle" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="活动介绍" prop="activityIntroduce">
                            <el-input :rows="5" v-model="form.activityIntroduce" type="textarea" placeholder="最多256个字"
                                maxlength="256" />
                        </el-form-item>
                    </el-col>
                </el-row>
                
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="所属客户" prop="customId">
                            <el-select :disabled="role.includes(1)" style="width: 100%;" clearable @change="changeCustom" v-model="form.customId" placeholder="请选择客户">
                                <el-option v-for="item in customList" :key="item.customId" :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="排序类型" prop="sortType">
                            <el-radio-group v-model="form.sortType">
                                <el-radio :label="0">图片优先</el-radio>
                                <el-radio :label="1">留言优先</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="授权用户" prop="memberList">
                            <el-table ref="memberList" border :data="tableData" style="width: 600px" max-height="250">
                                <el-table-column align="center" prop="adminAccount" label="账号">
                                    <template #default="scope">
                                        <el-input @blur="validateUsername(scope.row)" v-model="scope.row.username" placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="adminName" label="姓名">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.memberName" placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" fixed="right" label="操作" width="160">
                                    <template #default="scope">
                                        <el-button link type="primary" size="small"
                                            @click.prevent="deleteRow(scope.$index)">
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div style="width: 100%;">
                                <el-button plain type="primary" class="mt10" style="width: 600px"
                                    @click="onAddItem">增加</el-button>
                            </div>

                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24" class="custom">
                        <el-form-item label="活动签到本" prop="bookId">
                            <el-radio-group v-model="form.bookId">
                                <el-radio :label="item.bookId" v-for="item in signBookKist">
                                    <img :title="item.bookName" style="width: 120px;margin-top: 10px;height: 80px;"
                                        :src="item.coverUrl" alt="" class="image">
                                    <div :title="item.bookName" style="width: 100px;" class="text-one mb10">{{
                                        item.bookName }}</div>
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                
            </el-form>
        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:64px;' : 'left:201px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { formGroupForm } from "@/api/adminservice/form"
import { bookList } from "@/api/adminservice/public"
import { validatenull, isMobile } from '@/utils/validate'
import { teacherQueryOrRegUser } from "@/api/adminservice/classOrganizaMain"
import { zbhsignBookList,activityCreate,activityUpdate,activityInfo } from "@/api/adminservice/signIn"
import { queryCustoms, queryTreeCustomOrg, createCustoms, custominfo, configCustom, customOrgBook } from "@/api/adminservice/custom"
import { qunQueryUser, qunCreate, qunUpdate, queryCustomQun, queryQunInfo } from "@/api/adminservice/qun"
import { zhktQueryDict,schoolCreate,schoolUpdate,schoolInfo } from "@/api/adminservice/classOrganizaMain"

export default {
    name: "demoAdd",
    components: {},
    data() {
        return {
            signBookKist: [],
            classIsmList: [],
            memberList: [],
            formList: [],
            customId: '',
            groupData: {},
            id: '',
            activityId:'',
            readBook: false,
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                activityName: '',
                activityAddress: '',
                activityTitle:'',
                activitySubTitle:'',
                customId:'',
                memberList:[],
                bookId:'',
                sortType: 0,
            },
            tableData: [{
                adminAccount: '',
                orgName: ''
            }],
            // 表单校验
            rules: {
                sortType:[
                    { required: true, message: "请选择排序类型", trigger: "change" },
                ],
                activityName: [
                    { required: true, message: "请输入活动名称", trigger: "blur" },
                ],
                activityTitle: [
                    { required: true, message: "请输入活动标题", trigger: "blur" },
                ],
                customId:[
                    { required: true, message: "请选择客户", trigger: "change" },
                ],
                bookId: [
                    { required: true, message: "请选择活动签到本", trigger: "change" },
                ],
                memberList: [
                    { required: true, validator: this.checkOrgValidator }
                ]
            },
            bookList: [],
            customList: [],
            orgList: [],
            oldBookIds: [],
            oldFromIds: [],
            chooseBookIds: [],
            chooseBook: false,
            id: '',
            tableData: [],
            role: '',
        };
    },
    created() {
        this.role = this.$storage.get("role")
        this.getZhktQueryDict()
    },
    mounted() {
        this.getList()

        if (this.$route.query.activityId) {
            this.activityId = this.$route.query.activityId;
            this.getInfo()
        } else {
            if(this.$storage.get("role").includes(1)){
                this.form.customId = this.$storage.get("customId")
                this.changeCustom()
            }
        }
    },
    computed: {
    },
    methods: {
        getZhktQueryDict() {
            zbhsignBookList().then(res=>{
                this.signBookKist = res.data.data;
            })
        },
        checkMemberValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.memberList.length; i++) {
                var e = this.memberList[i];
                if (e.username == '' || e.memberName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        getInfo() {
            activityInfo({ activityId: this.activityId }).then(res => {
                this.form = res.data.data;
                this.tableData = this.form.memberList
            })
        },
        deleteRow(index) {
            console.log(index)
            if (this.tableData.length == 1) {
                this.tableData.splice(index, 1)
                // this.$message.error("不能全部删除")
            } else {
                this.tableData.splice(index, 1)
            }
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                username: '',
                memberName: '',
                userId: '',
            })
            setTimeout(() => {
                this.$refs.memberList.setScrollTop(1000000);
            })
        },
        checkOrgValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            if(this.tableData.length == 0){
                this.$message.error('请填写授权用户')
                result = false
            }
            for (var i = 0; i < this.tableData.length; i++) {
                var e = this.tableData[i];
                if (e.memberAccount == '' || e.memberName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        validateUsername(value,) {
            if (!isMobile(value.username)) {
                this.$message.error("手机号码格式不正确")
            }
            teacherQueryOrRegUser({ username: value.username, trueName: value.memberName }).then(res => {
                const result = res.data.data
                if (!result) {
                    value.username = ''
                    value.memberName = ''
                    this.$message.error("请输入正确账号")
                } else {
                    if (value.memberName == '') {
                        value.memberName = res.data.data.trueName
                    }
                    value.userId = res.data.data.userId
                    // callback();
                }
            })
        },
        validateMemberUsername(value) {
            qunQueryUser({ mobile: value.username }).then(res => {
                const result = res.data.data
                if (!result) {
                    value.username = ''
                    value.memberName = ''
                    this.$message.error("请输入正确账号")
                } else {
                    if(value.memberName == ''){
                        value.memberName = res.data.data.nickName
                    }
                    
                    value.userId = res.data.data.userId
                }
            })
        },
        changeCustom() {
            this.formList = [];
            this.orgList = [];
            queryTreeCustomOrg({ customId: this.form.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                console.log(res.data.data)
                this.orgList = res.data.data;
            })
        },
        deleteMemberRow(index) {
            this.memberList.splice(index, 1)
        },
        onMemberAddItem() {
            this.memberList.push({
                username: '',
                memberName: ''
            })
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;

            // bookList().then(res => {
            //     this.bookList = res.data.data;
            // });
            this.customList = []
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customType.includes('060');
                })
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
            });
        },

        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    console.log(this.form)
                    this.form.memberList = this.tableData
                    if (this.$route.query.activityId) {
                        activityUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/eventMain', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        activityCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/eventMain', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/eventMain')
            // this.$emit('isTg', true)
        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {
                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId
                    console.log(this.findParentNode(e.orgId))
                    if (this.findParentNode(e.orgId).indexOf(2) == -1) {
                        e.disabled = true
                    }
                    if (e.orgType == 5) {
                        // debugger
                        e.disabled = true
                        console.log(e)
                    }
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList
                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    /* max-height: 250px;
    overflow-y: auto; */
}

.custom /deep/ .el-radio {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-radio-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-radio__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}
</style>