<template>
    <div id="signIn" class="signIn">
        <div style="position: fixed;right: 15px;top:15px;z-index:999999">
            <screenfull id="screenfull" class="right-menu-item hover-effect cursor" />
        </div>
        <div class="bt-img">
            <div class="bt-lf-img">
                <img class="tl-big" src="../../assets/signIn/tl-big.png" alt="">
                <img class="tl-f" src="../../assets/signIn/tl-f.png" alt="">
                <img class="tl-y" src="../../assets/signIn/tl-y.png" alt="">
                <img class="tl-q" src="../../assets/signIn/tl-q.png" alt="">
            </div>
            <div class="bt-ct-img">
                <div class="bt-disflx" :style="`transform: translate(-50%, -50%) scale(${scale});z-index:99999;`">
                    <!-- <div class="lf-logo">
                        <img src="../../assets/code1.png" alt="">
                    </div> -->
                    <div class="lgrg-txt">
                        <div class="lgbgtxt" style="font-family:zdy;">{{ form.activityTitle }}</div>
                        <div class="lgsmtxt" style="font-family:source;">{{ form.activitySubTitle }} </div>
                    </div>
                </div>
                <img ref="tpimages" src="../../assets/signIn/bg-ct.png" alt="">
            </div>
            <div class="bt-rg-img">
                <img class="tr-bg" src="../../assets/signIn/tr-bg.png" alt="">
                <img class="tr-zw" src="../../assets/signIn/tr-zw.png" alt="">
                <img class="tr-s" src="../../assets/signIn/tr-s.png" alt="">
                <img class="tr-h" src="../../assets/signIn/tr-h.png" alt="">
                <div class="ewm" :style="`transform: scale(${scale});`">
                    <div style="width:70px;height: 70px;border: 5px;border-radius: 5px;margin-right: 5px;overflow: hidden;">
                        <vueQr :text="qrCode.shareUrl" margin="4" :size="qrCode.size" />
                    </div>
                    <div class="trt">
                        微信扫码
                    </div>
                    <div class="trt">
                        查看分享
                    </div>
                </div>
            </div>
        </div>
        <div :style="`height: ${kgb > 1.7?50:40}vh;margin-top: 4vh;`">
            <swiper v-if="isCanvas" :autoplay="cflautoplayObj" :loop="true" :coverflow-effect="cflowObj" effect="coverflow"
                :modules="cflmodules" :slides-per-view="slidesPerView" :space-between="50" :pagination="{ clickable: true }"
                @swiper="onSwiper" @slideChange="onSlideChange">
                <swiper-slide v-for="(item, index) in writeList">
                    <div class="">
                        <Svgbox :bookData="form.book" :imgList="[]" :imwidth="canvasWidth"
                            :imheight="form.book.pageHeight / form.book.pageWidth * canvasWidth"
                            :canvasid="'22dp333' + index" :picSrc="form.book.imgUrl" :strokeList="item.strokeList">
                        </Svgbox>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
        <div class="zdy" style="margin-top: 4vh;margin-left: 2vw;margin-right: 2vw;">
            <!-- <swiper v-if="issmCanvas" :freeMode="true" :speed="10000" :modules="modules" :autoplay="autoplayObj"
                :loop="true" :slides-per-view="smSlidesPerView" :space-between="10">
                <swiper-slide v-for="item in fileList">
                    <div class="swbor">
                        <img style="width: auto;height: 20vh;overflow: hidden;" :src="item.fileUrl" alt="">
                    </div>
                </swiper-slide>
            </swiper> -->
            <div id="cswidth" style="overflow: hidden;">
                <div>
                    <div id="zdytr" :style="`display: flex;transform: translateX(-${translateX}px);width: fit-content;`">
                        <img v-for="item in fileList" :style="`width: auto; height: ${kgb > 1.7?22:30}vh;margin: 0 5px;`"
                            :src="item.fileUrl" />
                        <!-- <img style="width: auto;height: 20vh;overflow: hidden;" :src="item.fileUrl" alt=""> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="bt-img rbottom">
            <div class="bb-lf-img">
                <img class="blb-big" src="../../assets/signIn/blb-big.png" alt="">
                <img class="blr-s" src="../../assets/signIn/blr-s.png" alt="">
            </div>
            <div class="bb-ct-img" :style="`height:${bcHeight}px`">
                <div class="br-bt-img">
                    <img src="../../assets/signIn/bl-y.png" alt="">
                </div>
                <img ref="bcimages" class="sc" src="../../assets/signIn/bg-bc.png" alt="">
                <div class="dw" :style="`transform: translate(-50%, -50%) scale(${scale});`">
                    <img src="../../assets/signIn/dw.png" alt="">
                    <div class="bt-txt">{{ form.activityAddress }}</div>
                </div>
            </div>
            <div class="bb-cr-img">
                <div class="br-bt-img">
                    <img src="../../assets/signIn/bl-y.png" alt="">
                </div>
                <img class="brb-big" src="../../assets/signIn/brb-big.png" alt="">
                <img class="brg-s" src="../../assets/signIn/brg-s.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import SvgCanvas from "@/components/SvgCanvas.vue";
import Screenfull from '@/components/Screenfull'
import { activityInfoNoLogin } from "@/api/adminservice/signIn"
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCoverflow } from 'swiper/modules';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css/autoplay'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { timestampToTime } from "@/utils/date";

export default {
    name: "signIn",
    components: {
        vueQr,
        Swiper,
        SwiperSlide,
        Screenfull,
        SvgCanvas
    },
    data() {
        return {
            translateX: 0,
            slidesPerView: 3,
            smSlidesPerView: 7,
            isCanvas: false,
            issmCanvas: false,
            cflmodules: [Autoplay, EffectCoverflow],
            modules: [Autoplay, EffectCoverflow],
            qrCode: {
                shareUrl: '',
                size: 60,
            },
            cflautoplayObj: {
                delay: 3000,//1秒切换一次
                disableOnInteraction: false,
                waitForTransition: true,
            },
            cflowObj: { rotate: -5, stretch: -100, depth: 100, modifier: 2, slideShadows: false },
            autoplayObj: {
                delay: 0,//1秒切换一次
                disableOnInteraction: false,
                waitForTransition: true,
            },
            bcHeight: 70,
            tpimages: 140,
            scale: 1,
            activityId: '',
            form: {},
            fileList: [],
            writeList: [],
            canvasWidth: 700,
            smLogo: 0,
            bgLogo: 0,
            kgb: 1.7,
        };
    },
    mounted() {
        console.log(3232);
        if (this.$route.query.activityId) {
            this.activityId = this.$route.query.activityId;
            this.qrCode.shareUrl = 'https://zbh.zbform.com/sign/#/?activityId=' + this.activityId
            this.getInfo()
        } else {
        }
        setTimeout(() => {
            window.onresize = () => {
                return (() => {
                    this.isCanvas = false;
                    this.issmCanvas = false;
                    this.watchHeight()
                })();
            };
        })
    },
    created() {
        document.addEventListener('selectstart', function (e) {
            e.preventDefault(); //阻止默认行为
        })
    },
    activated() {
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        getInfo() {
            activityInfoNoLogin({ activityId: this.activityId }).then(res => {
                this.form = res.data.data;
                this.writeList = res.data.data.writeList;
                
                if (this.writeList.length != 0) {
                    if (this.slidesPerView >= this.writeList.length) {
                        console.log(Math.floor(this.slidesPerView / this.writeList.length));
                        var smfoNum = Math.floor(this.slidesPerView / this.writeList.length);
                        for (var i = 0; i < smfoNum; i++) {
                            this.writeList = this.writeList.concat(res.data.data.writeList)
                        }
                    }
                    this.bgLogo = this.writeList.length;
                }
                this.fileList = res.data.data.fileList;
                if (this.fileList.length != 0) {
                    if (this.smSlidesPerView >= this.fileList.length) {
                        console.log(Math.floor(this.smSlidesPerView / this.fileList.length));
                        var smfoNum = Math.floor(this.smSlidesPerView / this.fileList.length);
                        for (var i = 0; i < smfoNum; i++) {
                            this.fileList = this.fileList.concat(res.data.data.fileList)
                        }
                    }
                    this.smLogo = this.fileList.length;
                }

                console.log(1212, this.fileList);

                setTimeout(() => {
                    this.watchHeight()
                    this.isCanvas = true;
                    this.issmCanvas = true;
                }, 1000)
            })
            setInterval(() => {
                activityInfoNoLogin({ activityId: this.activityId }).then(res => {
                    this.form = res.data.data;
                    this.writeList = res.data.data.writeList;
                    if (this.writeList.length != 0) {
                        if (this.slidesPerView >= this.writeList.length) {
                            // console.log(Math.floor(this.slidesPerView / this.writeList.length));
                            var smfoNum = Math.floor(this.slidesPerView / this.writeList.length);
                            for (var i = 0; i < smfoNum; i++) {
                                this.writeList = this.writeList.concat(res.data.data.writeList)
                            }
                        }
                        if (this.bgLogo != this.writeList.length) {
                            this.bgLogo = this.writeList.length;
                            this.isCanvas = false;
                        }
                        setTimeout(() => {
                            this.isCanvas = true;
                        })
                    }


                    this.fileList = res.data.data.fileList;
                    if (this.fileList.length != 0) {

                        if (this.smSlidesPerView >= this.fileList.length) {
                            console.log(Math.trunc(this.smSlidesPerView / this.fileList.length));
                            var smfoNum = Math.trunc(this.smSlidesPerView / this.fileList.length);
                            for (var i = 0; i < smfoNum; i++) {
                                this.fileList = this.fileList.concat(res.data.data.fileList)
                                this.fileList = this.fileList.concat(res.data.data.fileList)
                            }
                        }
                        // console.log(333, this.smLogo, this.fileList);
                        if (this.smLogo != this.fileList.length) {
                            this.smLogo = this.fileList.length;
                            this.issmCanvas = false;
                        }
                        setTimeout(() => {
                            this.issmCanvas = true;
                        })
                    }

                })
            }, 5000);
            setInterval(() => {
                // console.log(document.getElementById('zdytr').getBoundingClientRect().width);
                // console.log(document.getElementById('cswidth').offsetWidth);
                this.translateX = this.translateX + 0.3;
                if (this.translateX >= document.getElementById('zdytr').getBoundingClientRect().width - document.getElementById('cswidth').offsetWidth) {
                    this.translateX = 0;
                }
            }, 2);
            setInterval(() => {
                if (this.translateX >= document.getElementById('zdytr').getBoundingClientRect().width - document.getElementById('cswidth').offsetWidth) {
                    this.translateX = 0;
                }
            }, 1000);
            // setTimeout(() => {
            //     this.translateX = 0;
            // }, 3000)

        },
        watchHeight() {
            this.kgb = window.innerWidth / window.innerHeight

            let images = this.$refs["bcimages"];
            if (images) {
                this.bcHeight = images.height;
            }
            // this.tpimages = this.$refs["tpimages"].height;
            this.scale = window.innerHeight / 911
            console.log(this.kgb);
            if (this.kgb > 1.7) {
                var ybHeight = window.innerHeight * 0.5
            }else{
                var ybHeight = window.innerHeight * 0.40
            }
            this.canvasWidth = this.form.book.pageWidth / this.form.book.pageHeight * ybHeight
            console.log(this.form.book);
            console.log(this.canvasWidth);
            console.log(window.innerWidth / this.canvasWidth);
            console.log(32344, this.kgb);
            // alert(this.kgb)
            var intNum = Math.floor((window.innerWidth / this.canvasWidth))
            if (intNum % 2 === 0) {
                this.slidesPerView = intNum - 1
            } else {
                this.slidesPerView = intNum
            }
            if (this.slidesPerView == 1) {
                this.slidesPerView = 3
                this.cflowObj.stretch = -90;

            } else {
                this.cflowObj.stretch = -10;
            }
            // alert(this.kgb)
            if (this.kgb > 3.5 ) {
                this.cflowObj.stretch = -30;
            }
            if (this.kgb < 3.5 &&this.kgb > 3.0 ) {
                this.cflowObj.stretch = 20;
            }
            if (this.kgb < 3.0 &&this.kgb > 2.5 ) {
                this.cflowObj.stretch = 0;
            }
            if (this.kgb < 2.5 &&this.kgb > 2.0 ) {
                this.cflowObj.stretch = -30;
            }
            if (this.kgb < 2.0 && this.kgb > 1.7 ) {
                this.cflowObj.stretch = -90;
            }
            if (this.kgb < 1.7 && this.kgb > 1.5 ) {
                this.cflowObj.stretch = -70;
            }
            if (this.kgb < 1.5 && this.kgb > 1.2 ) {
                this.cflowObj.stretch = -90;
            }
            console.log(this.cflowObj.stretch);
            if (this.kgb > 1.7) {
                var ybHeight = window.innerHeight * 0.2;
                // console.log((window.innerWidth * 0.96) / (ybHeight * 1.5));
                this.smSlidesPerView = Math.floor((window.innerWidth * 0.96) / (ybHeight * 1.5) + 1)
            }else{
                var ybHeight = window.innerHeight * 0.25;
                // console.log((window.innerWidth * 0.96) / (ybHeight * 1.5));
                this.smSlidesPerView = Math.floor((window.innerWidth * 0.96) / (ybHeight * 1.5) + 1)
            }
            if (this.kgb < 1.4) {
                this.slidesPerView = 1;

            }else{
            }
            setTimeout(() => {
                this.isCanvas = true;
                this.issmCanvas = true;
            });
           

        },
        onSwiper(e) {
            // console.log(e);
        },
        onSlideChange(e) {
            // console.log(e);
        }
    }
};
</script>
<style scoped lang="scss">
.signIn {
    width: 100vw;
    height: 100vh;
    background-color: #d92121;
    background-image: url(../../assets/signIn/bg-dw.png);
    background-repeat: repeat-y;
    background-size: 100% auto;

    // .zdy {
    //     .swiper-slide {
    //         width: auto !important;
    //     }
    // }

    img {
        -webkit-user-drag: none;
    }

    .swbor {
        // border-radius: 15px;
        overflow: hidden;
    }

    .canvasBox {
        position: relative;
    }

    .imgbox {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }

    .bt-img {
        display: flex;
    }

    .bt-lf-img {
        width: 20.9vw;
        position: relative;

        .tl-big {
            width: 20.9vw;
            height: 12.5vh;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 99;
        }

        .tl-f {
            position: absolute;
            bottom: 0;
            width: 14vh;
            left: 0;
        }

        .tl-y {
            position: absolute;
            width: 30vh;
            left: 1vw;
            top: 0;
            z-index: 99999;
        }

        .tl-q {
            position: absolute;
            width: 8vh;
            right: 0;
            bottom: 0;
            z-index: 9;
        }
    }

    .bt-ct-img {
        width: 55vw;
        position: relative;

        .bt-disflx {
            display: flex;
            position: absolute;
            left: 50%;
            top: 47%;

            .lf-logo {
                width: 5vh;
                height: 5vh;
                border: 4px solid #ffe3a6;
                border-radius: 50%;
                overflow: hidden;

                img {
                    width: 5vh;
                    height: 5vh;
                }
            }

            color: #fcd7a6;
            text-align: center;
            white-space: nowrap;

            .lgrg-txt {
                margin-left: 3em;
            }

            .lgbgtxt {
                font-size: 3em;
            }

            .lgsmtxt {
                margin-top: 0.5em;
                font-size: 1.2em;
            }

        }

        img {
            width: 55vw;
            height: 12.5vh;
        }
    }

    .bt-rg-img {
        width: 24vw;
        position: relative;

        .ewm {
            position: absolute;
            z-index: 9999;
            right: 5%;
            bottom: 0;
            display: flex;
            align-items: center;

            img {
                width: 70px;
                height: 70px;
                border: 5px;
                margin-right: 5px;
            }

            .trt {
                width: 15px;
                padding: 1px;
                font-size: 12px;
                color: #ffe3a6;
            }
        }

        .tr-bg {
            width: 24vw;
            height: 9.7vh;
            position: absolute;
            right: 0;
            z-index: 99;
        }

        .tr-zw {
            width: 5vh;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 999;
        }

        .tr-s {
            width: 25vh;
            height: 20vh;
            position: absolute;
            right: 0;
            top: -5vh;
            z-index: 15;
        }

        .tr-h {
            width: 15vh;
            position: absolute;
            left: 0;
            top: 0vh;
        }
    }

    .rbottom {
        align-items: flex-end;
        position: fixed;
        bottom: -5px;
        left: 0;
        right: 0;
        z-index: 999;

        .bb-lf-img {
            width: 26vw;
            position: relative;

            .blb-big {
                position: absolute;
                z-index: 99;
                bottom: 0;
                height: 8vh;
                width: 100%;
                left: 0;
            }

            .blr-s {
                position: absolute;
                z-index: 999;
                bottom: 0;
                height: 16vh;
                width: 16vh;
                left: 0;
            }
        }

        .bb-ct-img {
            width: 47vw;
            position: relative;
            height: max-content;

            .sc {
                position: absolute;
                z-index: 99;
                bottom: 0;
                height: 7vh;
                width: 100%;
                left: 0;
            }

            .br-bt-img {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 0;

                img {
                    width: 28vh;
                    transform: translateX(-50%);

                }
            }

            .dw {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                align-items: center;
                z-index: 9999;

                img {
                    width: 15px;
                }

                .bt-txt {
                    color: #fcd7a6;
                    margin-left: 15px;
                }
            }
        }

        .bb-cr-img {
            position: relative;
            width: 27vw;

            .brb-big {
                position: absolute;
                z-index: 99;
                bottom: 0;
                height: 8vh;
                width: 100%;
                left: 0;
            }

            .br-bt-img {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 0;

                img {
                    width: 28vh;
                    transform: translateX(-50%);
                }
            }

            .brg-s {
                position: absolute;
                z-index: 999;
                bottom: 0;
                height: 16vh;
                width: 16vh;
                right: 0;
            }
        }

        img {
            width: 100%;
        }
    }

    .el-carousel__item {
        overflow: auto;
    }

    .el-carousel__item h3 {
        color: #475669;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
        text-align: center;
    }

    .el-carousel__item:nth-child(2n) {

        // background-color: #99a9bf;
        img {
            transform: rotateZ(-12deg) rotateY(44deg) !important;
        }
    }

    .el-carousel__item:nth-child(2n + 1) {

        // background-color: #d3dce6;
        img {
            transform: rotateY(200deg) !important;
        }
    }
}
</style>