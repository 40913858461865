/*路由配置*/
import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import UserLogin from "@/views/public/userLogin";
import CodeLogin from "@/views/public/codeLogin"
import ResetPwd from "@/views/public/resetPwd"
import GroupHome from "@/views/groupHome"
import WelHome from "@/views/public/welHome"
import OrganizeManage from "@/views/zbh/organize/organizeManage"
import GroupManage from "@/views/zbh/group/groupManage"
import JoinGroup from "@/views/public/joinGroup"
import AdminGroup from "@/views/zbh/adminGroup/adminGroupManage"
import UserInfo from "@/views/zbh/user/userInfo"
import GroupInfo from "@/views/zbh/user/groupInfo"
import Policy from "@/views/public/policy"
import WorkBook from "@/views/public/workBook"
import WriteInfo from "@/views/zbh/write/writeInfo"
import InviteMember from "@/views/public/inviteMember"

import CustomerManage from "@/views/customerManage/index"

import platformAdmin from "@/views/customerManage/platformAdmin"

import editManage from "@/views/customerManage/editManage"

import businessModule from "@/views/customerManage/businessModule"

import CustomerAdd from "@/views/customerManage/customerAdd"

import CustomerDetail from "@/views/customerManage/customerDetail"

import ArrayMaintenance from "@/views/arrayMaintenance/copytext"

import GroupSet from "@/views/groupSet/index"

import GroupSetAdd from "@/views/groupSet/groupSetAdd"

import groupSetDetail from "@/views/groupSet/groupSetDetail"

import ScenarioMainAdd from "@/views/scenarioMain/scenarioMainAdd"

import ScenarioDetail from "@/views/scenarioMain/scenarioDetail"

import ScenarioMain from "@/views/scenarioMain/index"

import FormMain from "@/views/formMain/index"

import FormMainAdd from "@/views/formMain/formMainAdd"

import FormDetail from "@/views/formMain/formDetail"

import FormRecord from "@/views/formRecord/index"

import FormRecordDetail from "@/views/formRecord/formRecordDetail"

import FormRecordIdentify from "@/views/formRecord/formRecordIdentify"

import FormRecordProofreading from "@/views/formRecordProofreading/index"

import FormDataQuery from "@/views/formDataQuery/index"

import postScenarioMain from "@/views/postScenarioMain/index"

import postScenarioDetail from "@/views/postScenarioMain/postScenarioDetail"

import postScenarioAdd from "@/views/postScenarioMain/postScenarioAdd"

import cardMain from "@/views/cardMain/index"

import cardMainAdd from "@/views/cardMain/cardMainAdd"

import cardMainDetail from "@/views/cardMain/cardMainDetail"

import classOrganizaMain from "@/views/classOrganizaMain/index"

import classOrganizaMainAdd from "@/views/classOrganizaMain/classOrganizaMainAdd"

import classOrganizaMainDetail from "@/views/classOrganizaMain/classOrganizaMainDetail"

import classManagement from "@/views/classManagement/index"
import classManagementAdd from "@/views/classManagement/classManagementAdd"
import classManagementBatchAdd from "@/views/classManagement/classManagementBatchAdd"
import classManagementVirtualAdd from "@/views/classManagement/classManagementVirtualAdd"
import classManagementDetail from "@/views/classManagement/classManagementDetail"

import teacherMain from "@/views/teacherMain/index"
import teacherMainAdd from "@/views/teacherMain/teacherMainAdd"
import teacherMainDeatil from "@/views/teacherMain/teacherMainDeatil"

import studentMain from "@/views/studentMain/index"
import studentMainAdd from "@/views/studentMain/studentMainAdd"
import studentMainDeatil from "@/views/studentMain/studentMainDeatil"

import lessPreparatManage from "@/views/lessPreparatManage/index"
import lessPreparatAdd from "@/views/lessPreparatManage/lessPreparatAdd"
import lessPreparatDetail from "@/views/lessPreparatManage/lessPreparatDetail"

import eventMain from "@/views/eventMain/index"
import eventMainAdd from "@/views/eventMain/eventMainAdd"
import eventMainDetail from "@/views/eventMain/eventMainDetail"

import teachAssistantMain from "@/views/teachAssistantMain/index"
import teachAssistantAdd from "@/views/teachAssistantMain/teachAssistantAdd"
import teachAssistantDetail from "@/views/teachAssistantMain/teachAssistantDetail"

import classErrorReport from "@/views/reviewSee/classErrorReport"
import classStudentReport from "@/views/reviewSee/classStudentReport"
import classGrading from "@/views/reviewSee/classGrading"
import classIncorrectAnswers from "@/views/reviewSee/classIncorrectAnswers"
import ChapterReport from "@/views/reviewSee/ChapterReport"

import commentSet from "@/views/commentSet/index"
import commentSetAdd from "@/views/commentSet/commentSetAdd"
import commentSetDetail from "@/views/commentSet/commentSetDetail"

import classRecords from "@/views/classRecords/index"
import classRecordsDetail from "@/views/classRecords/classRecordsDetail"

import teachingAidsStructure from "@/views/teachAssistantMain/teachingAidsStructure"

import noteOtherBooks from "@/views/noteOtherBooks/index"
import ReadBook from "@/views/noteOtherBooks/readbook"
import overviewHand from "@/views/noteOtherBooks/overviewHand"
import noteTask from "@/views/noteTask/index"
import noteTaskAdd from "@/views/noteTask/noteTaskAdd"
import noteTaskDetail from "@/views/noteTask/noteTaskDetail"
import noteTaskZXDetail from "@/views/noteTask/noteTaskZXDetail"
import noteTaskReadBook from "@/views/noteTask/noteTaskReadBook"
import naskToDo from "@/views/noteTask/naskToDo"
import naskToDoDetail from "@/views/noteTask/naskToDoDetail"

import postoncardRecordQuery from "@/views/postcardRecordQuery/index"

import ZBDesigner from "@/extern/src/src/App"

import teachAssistantDesignView from "@/extern/src/src/components/teachAssistantDesignView"

import configureDesignView from "@/extern/src/src/components/configureDesignView"

import smartBookManage from "@/views/smartBookManage/index"

import signIn from "@/views/signIn/index"

import homePage from "@/views/public/homePage"

import editor from "@/views/editor"

import penAuthApply from "@/views/penMenage/penAuthApply"

import penMenage from "@/views/penMenage/index"

import indexs from "@/views/index.vue"

const routes = [
    { path: '/', redirect: '/login' },
    { path: '/login', name: '用户账号登录', meta: { title: '孜博汇-用户账号登录' }, component: UserLogin },
    { path: '/inviteMember', name: '场景邀请成员', meta: { title: '纸笔智能服务平台' }, component: InviteMember },
    { path: '/codeLogin', name: '用户验证码登录', meta: { title: '孜博汇-用户验证码登录' }, component: CodeLogin },
    { path: '/resetPwd', name: '用户重置密码', meta: { title: '孜博汇-用户重置密码' }, component: ResetPwd },
    { path: '/joinGroup', name: '用户加群', meta: { title: '孜博汇-用户加群' }, component: JoinGroup },
    { path: '/policy', name: '群笔记隐私政策', meta: { title: '孜博汇-群笔记隐私政策' }, component: Policy },
    { path: '/workBook', name: '群笔记操作手册', meta: { title: '孜博汇-群笔记操作手册' }, component: WorkBook },
    { path: '/ZBDesigner', name: '点阵表单设计器', meta: { title: '孜博汇-点阵表单设计器' }, component: ZBDesigner },
    { path: '/teachAssistantDesignView',name:'教辅点阵表单设计器',meta:{ title:'孜博汇-教辅点阵表单设计器' },component:teachAssistantDesignView },
    { path: '/configureDesignView',name:'智能本配置设计器',meta:{ title:'孜博汇-智能本配置设计器' },component:configureDesignView },
    { path: '/editor', name: '編輯器', meta: { title: '孜博汇-編輯器' }, component: editor },
    { path: '/signIn', name: '签到', meta: { title: '签到' }, component: signIn },
    { path: '/readBook', name: '云本查看详情',meta: { title: '云本查看详情' }, component: ReadBook },
    { path: "/noteTaskReadBook", name: "noteTaskReadBook", meta: { title: '群任务查看详情', hidden: true }, component: noteTaskReadBook },
    { path: '/overviewHand', name: '笔迹概览详情',meta: { title: '笔迹概览详情' }, component: overviewHand },
    
    { path: '/homePage', name: '主页', meta: { title: '主页' }, component: homePage },
    {
        path: '/home',
        name: "首页",
        component: GroupHome,
        redirect: "/indexs",
        meta: { title: '首页-孜博汇管理中心' },
        children: [
            {
                path: '/indexs',
                component: indexs,
                meta: { title: '首页' },
            },
            { path: "/welHome", name: "用户总览", meta: { title: '孜博汇-组织总览' }, component: GroupInfo },
            { path: "/groupManage", name: "群管理", meta: { title: '孜博汇-群管理' }, component: GroupManage },
            { path: "/organizeManage", name: "组管理", meta: { title: '孜博汇-群组管理' }, component: OrganizeManage },
            { path: "/adminGroup", name: "管理权限", meta: { title: '孜博汇-管理员群' }, component: AdminGroup },
            { path: "/user", name: "个人信息", meta: { title: '孜博汇-个人信息' }, component: UserInfo },
            { path: "/group", name: "组织信息", meta: { title: '孜博汇-组织信息' }, component: GroupInfo },
            { path: "/write", name: "智能本管理", meta: { title: '孜博汇-智能本管理' }, component: WriteInfo },
            
            { path: "/platformAdmin", name: "platformAdmin", meta: { title: '管理员管理', keepAlive: true }, component: platformAdmin },

            { path: "/editManage", name: "editManage", meta: { title: '更新内容管理', keepAlive: true }, component: editManage },
            
            { path: "/businessModule", name: "businessModule", meta: { title: '业务模块', keepAlive: true }, component: businessModule },

            { path: "/customerManage", name: "customerManage", meta: { title: '客户管理', keepAlive: true }, component: CustomerManage },

            { path: "/penAuthApply", name: "penAuthApply", meta: { title: '智能笔授权管理', keepAlive: true }, component: penAuthApply },

            { path: "/penMenage", name: "penMenage", meta: { title: '智能笔管理', keepAlive: true }, component: penMenage },

            { path: "/smartBookManage", name: "smartBookManage", meta: { title: '智能本管理', keepAlive: true }, component: smartBookManage },

            { path: "/customerManageAdd", name: "customerManageAdd", meta: { title: '新增客户', hidden: true }, component: CustomerAdd },

            { path: "/customerDetail", name: "customerDetail", meta: { title: '客户详情' }, component: CustomerDetail },

            { path: "/arrayMaintenance", name: "arrayMaintenance", meta: { title: '组织结构', keepAlive: true }, component: ArrayMaintenance },

            { path: "/groupSet", name: "groupSet", meta: { title: '群组管理', keepAlive: true }, component: GroupSet },

            { path: "/groupSetAdd", name: "groupSetAdd", meta: { title: '新增群组', hidden: true }, component: GroupSetAdd },

            { path: "/groupSetDetail", name: "groupSetDetail", meta: { title: '群组详情', hidden: true }, component: groupSetDetail },

            { path: "/scenarioMain", name: "scenarioMain", meta: { title: '场景管理', keepAlive: true }, component: ScenarioMain },

            { path: "/scenarioDetail", name: "scenarioDetail", meta: { title: '场景详情', hidden: true }, component: ScenarioDetail },

            { path: "/scenarioMainAdd", name: "scenarioMainAdd", meta: { title: '新增场景', keepAlive: false, hidden: true }, component: ScenarioMainAdd },

            { path: "/formMain", name: "formMain", meta: { title: '表单管理', keepAlive: true }, component: FormMain },

            { path: "/formMainAdd", name: "formMainAdd", meta: { title: '表单管理', hidden: true }, component: FormMainAdd },

            { path: "/formDetail", name: "formDetail", meta: { title: '表单详情', hidden: true }, component: FormDetail },

            { path: "/formRecord", name: "formRecord", meta: { title: '表单记录', keepAlive: true }, component: FormRecord },

            { path: "/formRecordDetail", name: "formRecordDetail", meta: { title: '表单记录查看', hidden: true }, component: FormRecordDetail },

            { path: "/formRecordIdentify", name: "formRecordIdentify", meta: { title: '表单记录查看', hidden: true }, component: FormRecordIdentify },

            { path: "/formTTTRecordProofreading", name: "formTTTRecordProofreading", meta: { title: '表单记录校审', keepAlive: true }, component: FormRecordProofreading },

            { path: "/formDataQuery", name: "formDataQuery", meta: { title: '表单数据查询', keepAlive: true }, component: FormDataQuery },

            { path: "/postScenarioMain", name: "postScenarioMain", meta: { title: '明信片场景管理', keepAlive: true }, component: postScenarioMain },

            { path: "/postScenarioDetail", name: "postScenarioDetail", meta: { title: '明信片场景详情', hidden: true }, component: postScenarioDetail },

            { path: "/postScenarioAdd", name: "postScenarioAdd", meta: { title: '新增明信片场景', keepAlive: false, hidden: true }, component: postScenarioAdd },

            { path: "/postoncardRecordQuery", name: "postoncardRecordQuery", meta: { title: '明信片记录查询', keepAlive: true }, component: postoncardRecordQuery },

            { path: "/cardMain", name: "cardMain", meta: { title: '明信片管理', keepAlive: true }, component: cardMain },

            { path: "/cardMainAdd", name: "cardMainAdd", meta: { title: '明信片管理', keepAlive: false, hidden: true }, component: cardMainAdd },

            { path: "/cardMainDetail", name: "cardMainDetail", meta: { title: '明信片管理', keepAlive: false, hidden: true }, component: cardMainDetail },

            { path: "/classOrganizaMain", name: "classOrganizaMain", meta: { title: '学校维护', keepAlive: true }, component: classOrganizaMain },

            { path: "/classOrganizaMainAdd", name: "classOrganizaMainAdd", meta: { title: '新增学校', keepAlive: false, hidden: true }, component: classOrganizaMainAdd },

            { path: "/classOrganizaMainDetail", name: "classOrganizaMainDetail", meta: { title: '学校详情', keepAlive: false, hidden: true}, component: classOrganizaMainDetail },

            { path: "/classManagement", name: "classManagement", meta: { title: '班级管理', keepAlive: true }, component: classManagement },
            { path: "/classManagementAdd", name: "classManagementAdd", meta: { title: '编辑班级', hidden: true }, component: classManagementAdd },
            { path: "/classManagementBatchAdd", name: "classManagementBatchAdd", meta: { title: '批量创建班级', hidden: true }, component: classManagementBatchAdd },
            { path: "/classManagementVirtualAdd", name: "classManagementVirtualAdd", meta: { title: '创建虚拟班级', hidden: true }, component: classManagementVirtualAdd },

            
            { path: "/classManagementDetail", name: "classManagementDetail", meta: { title: '班级详情', hidden: true }, component: classManagementDetail },

            { path: "/teacherMain", name: "teacherMain", meta: { title: '教师维护', keepAlive: true }, component: teacherMain },
            { path: "/teacherMainAdd", name: "teacherMainAdd", meta: { title: '编辑教师', hidden: true }, component: teacherMainAdd },
            { path: "/teacherMainDeatil", name: "teacherMainDeatil", meta: { title: '教师详情', hidden: true }, component: teacherMainDeatil },

            { path: "/studentMain", name: "studentMain", meta: { title: '学生维护', keepAlive: true }, component: studentMain },
            { path: "/studentMainAdd", name: "studentMainAdd", meta: { title: '编辑学生', hidden: true }, component: studentMainAdd },
            { path: "/studentMainDeatil", name: "studentMainDeatil", meta: { title: '学生详情', hidden: true }, component: studentMainDeatil },

            { path: "/lessPreparatManage", name: "lessPreparatManage", meta: { title: '备课管理', keepAlive: true }, component: lessPreparatManage },
            { path: "/lessPreparatAdd", name: "lessPreparatAdd", meta: { title: '编辑学生', hidden: true }, component: lessPreparatAdd },
            { path: "/lessPreparatDetail", name: "lessPreparatDetail", meta: { title: '学生详情', hidden: true }, component: lessPreparatDetail },

            { path: "/eventMain", name: "eventMain", meta: { title: '活动管理', keepAlive: true }, component: eventMain },
            { path: "/eventMainAdd", name: "eventMainAdd", meta: { title: '新增活动', hidden: true }, component: eventMainAdd },
            { path: "/eventMainDetail", name: "eventMainDetail", meta: { title: '活动详情', hidden: true }, component: eventMainDetail },
            
            { path: "/teachAssistantMain", name: "teachAssistantMain", meta: { title: '教辅维护', keepAlive: true }, component: teachAssistantMain },
            { path: "/teachAssistantAdd", name: "teachAssistantAdd", meta: { title: '新增教辅', hidden: true }, component: teachAssistantAdd },
            { path: "/teachAssistantDetail", name: "teachAssistantDetail", meta: { title: '教辅详情', hidden: true }, component: teachAssistantDetail },

            { path: "/classErrorReport", name: "classErrorReport", meta: { title: '班级错题报告', keepAlive: true }, component: classErrorReport },
            { path: "/classStudentReport", name: "classStudentReport", meta: { title: '班级学生报告', keepAlive: true }, component: classStudentReport },
            { path: "/classGrading", name: "classGrading", meta: { title: '班级批阅对比统计', keepAlive: true }, component: classGrading },
            { path: "/classIncorrectAnswers", name: "classIncorrectAnswers", meta: { title: '班级答错对比统计', keepAlive: true }, component: classIncorrectAnswers },
            { path: "/ChapterReport", name: "ChapterReport", meta: { title: '教辅章节批阅报告', keepAlive: true }, component: ChapterReport },

            

            { path: "/commentSet", name: "commentSet", meta: { title: '奖评设置', keepAlive: true }, component: commentSet },
            { path: "/commentSetAdd", name: "commentSetAdd", meta: { title: '新增奖评', hidden: true }, component: commentSetAdd },
            { path: "/commentSetDetail", name: "commentSetDetail", meta: { title: '奖评详情', hidden: true }, component: commentSetDetail },
            
            { path: "/classRecords", name: "classRecords", meta: { title: '课堂记录', keepAlive: true }, component: classRecords },
            { path: "/classRecordsDetail", name: "classRecordsDetail", meta: { title: '课堂记录详情', hidden: true }, component: classRecordsDetail },

            { path: "/teachingAidsStructure", name: "teachingAidsStructure", meta: { title: '教辅结构维护', keepAlive: true }, component: teachingAidsStructure },

            { path: "/noteOtherBooks", name: "noteOtherBooks", meta: { title: '查看群本', keepAlive: true }, component: noteOtherBooks },
            { path: "/noteTask", name: "noteTask", meta: { title: '任务管理', keepAlive: true }, component: noteTask },
            { path: "/noteTaskAdd", name: "noteTaskAdd", meta: { title: '新增群任务', hidden: true }, component: noteTaskAdd },
            { path: "/noteTaskDetail", name: "noteTaskDetail", meta: { title: '群任务详情', hidden: true }, component: noteTaskDetail },
            { path: "/noteTaskZXDetail", name: "noteTaskZXDetail", meta: { title: '群任务执行详情', hidden: true }, component: noteTaskZXDetail },

            { path: "/naskToDo", name: "naskToDo", meta: { title: '待办任务', keepAlive: true }, component: naskToDo },
            { path: "/naskToDoDetail", name: "naskToDoDetail", meta: { title: '待办任务详情', hidden: true }, component: naskToDoDetail },
            
        ]
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path === '/login' ||
        to.path === '/codeLogin' ||
        to.path === '/resetPwd' ||
        to.path === '/joinGroup' ||
        to.path === '/workBook' ||
        to.path === '/inviteMember' ||
        to.path === '/editor' ||
        to.path === '/signIn' ||
        to.path === '/policy') return next()
    // 获取token
    const tokenStr = window.localStorage.getItem('token')
    if (!tokenStr) return next('/login')
    window.document.title = to.meta.title
    next()
})

export default router
