<template>
    <div class="app-container" @click="visible = false;">
        <el-card class="mb15">
            <el-row>
                <el-col :span="20" class="mb0">
                    <el-button type="primary" @click="handleAdd">新增客户</el-button>
                </el-col>
                <el-col :span="4" class="mr0 mb0">
                    <div class="pull-right">
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true"
                                label-width="68px" @submit.native.prevent="getList">
                                <el-row class="mt15">
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="客户状态" class="w-100">
                                            <el-select clearable filterable class="w-100" v-model="queryParams.status"
                                                placeholder="请选择">
                                                <el-option :key="0" :label="'正式客户'" :value="0" />
                                                <el-option :key="1" :label="'演示客户'" :value="1" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="客户类型" class="w-100">
                                            <el-select clearable class="w-100" v-model="queryParams.customType"
                                                placeholder="请选择">
                                                <el-option :key="1" :label="'表单客户'" :value="1" />
                                                <el-option :key="2" :label="'群笔记客户'" :value="2" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="停用状态" class="w-100">
                                            <el-select clearable class="w-100" v-model="queryParams.isStop"
                                                placeholder="请选择">
                                                <el-option :key="0" :label="'正常'" :value="0" />
                                                <el-option :key="1" :label="'停用'" :value="1" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class="mr0">
                                        <el-form-item label="客户名称" class="w-100">
                                            <el-input clearable class="w-100" v-model="queryParams.customName" placeholder="请输入客户名称">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <div style="margin:0 auto;">
                                        <el-button @click="getList" type="primary">查找</el-button>
                                    </div>
                                </el-row>
                            </el-form>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <!-- <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                @submit.native.prevent="handleQuery">
                <el-row>
                    <el-col :span="6" class="">
                        <el-form-item label="客户状态">
                            <el-select clearable filterable @change="getList" v-model="queryParams.status"
                                placeholder="请选择">
                                <el-option :key="0" :label="'正式客户'" :value="0" />
                                <el-option :key="1" :label="'演示客户'" :value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-form-item label="客户类型">
                            <el-select clearable @change="getList" v-model="queryParams.customType" placeholder="请选择">
                                <el-option :key="1" :label="'表单客户'" :value="1" />
                                <el-option :key="2" :label="'群笔记客户'" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="">
                        <el-form-item label="停用状态">
                            <el-select clearable @change="getList" v-model="queryParams.isStop" placeholder="请选择">
                                <el-option :key="0" :label="'正常'" :value="0" />
                                <el-option :key="1" :label="'停用'" :value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mr0 ">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.customName" placeholder="请输入客户名称" class="input-with-select">
                                <template #append>
                                    <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-divider style="position: relative;" v-if="isSxShow">
                <img @click="isSxShow = false;" style="transform: translateY(-100%);" class="sxShowcss"
                    src="../../assets/img/arrow-up.png" alt="">
            </el-divider> -->
            <div class="bre9e mt15" style="position: relative;">
                <img @click="isSxShow = true;" v-if="!isSxShow" style="transform: translateY(-36px);" class="sxShowcss"
                    src="../../assets/img/arrow-down.png" alt="">
                <el-table header-cell-class-name="bgf2" highlight-current-row class="sssstt"
                    :height="'calc(100vh - ' + (isSxShow ? '270' : '270') + 'px)'" v-loading="loading" :data="dataList">
                    <el-table-column align="center" width="90px" label="客户状态" prop="status">
                        <template #default="scope">
                            <div v-if="scope.row.status == 0">
                                正式客户
                            </div>
                            <div v-if="scope.row.status == 1">
                                演示客户
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="280px" label="客户名称" prop="customName">
                        <template #default="scope">
                            <div :title="scope.row.customName" style="color: #409efe;" class="text-one cursor"
                                @click="goDetail(scope.row)">
                                {{ scope.row.customName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="120px" label="客户简称" prop="customAbbreviation" />
                    <el-table-column align="center" width="500px" label="业务类型" prop="customTxt">
                        <template #default="scope">
                            <div :title="scope.row.customTxt" class="text-one">
                                {{ scope.row.customTxt }}
                            </div>
                        </template>
                    </el-table-column>

                    <!-- <el-table-column align="center" width="120px" label="资源库" prop="bookIds">
                    <template #default="scope">
                        <el-button type="primary" icon="el-icon-view" size="small" @click.prevent="readbookIds(scope.row)">
                            云本类型
                        </el-button>
                    </template>
                </el-table-column> -->
                    <el-table-column align="center" fixed="right" width="150" label="编辑"
                        class-name="small-padding fixed-width">
                        <template #default="scope">

                            <el-button link icon="Edit" type="primary" @click="handleUpdate(scope.row)">修改
                            </el-button>
                            <el-popconfirm  v-if="isPlatform == 0" confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                icon-color="#626AEF" title="确定删除该客户" @confirm="handleDelete(scope.row,scope.$index)">
                                <template #reference>
                                    <el-button link icon="Delete" type="danger">删除
                                    </el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="isPlatform == 0" align="center" fixed="right" width="90px" label="操作"
                        class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-popconfirm v-if="scope.row.isStop == 0" confirm-button-text="是" cancel-button-text="否"
                                :icon="InfoFilled" icon-color="#626AEF" title="确定停用该客户" @confirm="handleStop(scope.row)">
                                <template #reference>
                                    <el-button size="mini" type="primary">停用
                                    </el-button>
                                </template>
                            </el-popconfirm>
                            <el-popconfirm v-if="scope.row.isStop == 1" confirm-button-text="是" cancel-button-text="否"
                                :icon="InfoFilled" icon-color="#626AEF" title="确定启用该客户" @confirm="handleStop(scope.row)">
                                <template #reference>
                                    <el-button size="mini" type="danger">启用
                                    </el-button>
                                </template>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <!-- <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize" @pagination="getList" /> -->
        </el-card>
        <el-dialog :title="'云本资源'" v-model="readBook" width="60%">
            <div class="fl-warp" style="display: flex;">
                <div v-for="item in readBookList" class="csbbb">
                    <img style="width: 100px;margin-top: 10px;"
                        :src="this.$store.state.zdy.imgUrl + '/book/' + item.id + '/img/cover.png_original'" alt=""
                        class="image">
                    <div style="width: 100px;text-align: center;" class="text-one mb15">{{ item.bookName }}</div>
                </div>
            </div>

        </el-dialog>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { bookList } from "@/api/adminservice/public"
const router = useRouter();
import {
    queryAllGroup,
    getData,
    delData,
    addData,
    updateData,
    exportData
} from "@/api/demo/data";
import { timestampToTime } from "@/utils/date";
import { queryCustoms, stopCustoms, delCustoms } from "@/api/adminservice/custom";

export default {
    name: "customerManage",
    components: {
    },
    data() {
        return {
            isPlatform:this.$storage.get('isPlatform'),
            visible: false,
            isSxShow: true,
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            readBook: false,
            readBookList: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                status: '',
                customName: '',
                isStop: '',
                customType: ''
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            }
        };
    },
    created() {
        this.getList();
        this.getBookList();
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        readbookIds(row) {
            this.readBookList = [];
            this.bookList.forEach(e => {
                row.bookIds.forEach(j => {
                    if (e.id == j) {
                        this.readBookList.push(e)
                    }
                })
            })
            this.readBook = true;
        },
        getBookList() {
            bookList().then(res => {
                this.bookList = res.data.data;
            });
        },
        tgMethod(e) {
            this.queryParams.customName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                if (this.queryParams.customName !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.customName.indexOf(this.queryParams.customName) != -1
                    })
                }
                if (this.queryParams.status !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.status == this.queryParams.status
                    })
                }
                if (this.queryParams.isStop !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.isStop == this.queryParams.isStop
                    })
                }
                if (this.queryParams.customType !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.customType.indexOf(this.queryParams.customType) != -1
                    })
                }

                res.data.data.forEach((e, i) => {
                    e.beginDate = timestampToTime(e.beginDate).substring(0, 10)
                    e.endDate = timestampToTime(e.endDate).substring(0, 10)
                    e.formBeginDate = timestampToTime(e.formBeginDate).substring(0, 10)
                    e.formEndDate = timestampToTime(e.formEndDate).substring(0, 10)
                    if (e.customType) {
                        e.customTypeList = e.customType && e.customType.split(',')
                    } else {
                        e.customTypeList = [];
                    }
                    e.customTxt = ''
                    // <el-checkbox label="2" key="2">党建客户</el-checkbox>
                    // <el-checkbox label="3" key="3">教研客户</el-checkbox>
                    // <el-checkbox label="4" key="4">教学客户</el-checkbox>
                    var txtList = []
                    e.customTypeList.forEach((j, k) => {
                        if (j == '010') {
                            txtList.push('智能表单')
                        } else if (j == '020') {
                            txtList.push('互动课堂')
                        } else if (j == '030') {
                            txtList.push('群笔记')
                        } else if (j == '040') {
                            txtList.push('批阅')
                        } else if (j == '050') {
                            txtList.push('明信片')
                        } else if (j == '060') {
                            txtList.push('签到')
                        }
                    })
                    e.customTxt = txtList.toString(',')
                })
                this.dataList = res.data.data;
            });
        },

        // 停用
        handleStop(row) {
            stopCustoms({ customId: row.customId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("操作成功");
                    if(row.isStop == 0){
                        row.isStop = 1
                    }else{
                        row.isStop = 0
                    }
                    // this.getList();
                } else {
                    this.$message.error("操作失败");
                }
            })
        },
        /** 删除按钮操作 */
        handleDelete(row,index) {
            delCustoms({ customId: row.customId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.dataList.splice(index,1)
                    // this.getList();
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        goDetail(row) {
            this.$router.push({
                path: "/customerDetail",
                query: {
                    customId: row.customId,
                }
            });
        },
        /** 导出按钮操作 */
        handleExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出所有基础：基数数据数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(function () {
                    return exportData(queryParams);
                })
                .then(response => {
                    this.download(response.msg);
                });
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.did);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/customerManageAdd",
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/customerManageAdd",
                query: {
                    customId: row.customId,
                }
            });
            // this.taskdata = row;
            // this.open = true;
        }
    }
};
</script>
<style scoped>
.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}</style>