import request from "@/utils/request";
// 手机号查询用户信息
export const  qunQueryUser =(query) =>{
    return request({
        url: "/adminservice/qun/queryUser",
        method:"get",
        params: query
    })
}
// 手机号查询用户信息
export const  adminqueryUser =(query) =>{
    return request({
        url: "/authservice/admin/queryUser",
        method:"get",
        params: query
    })
}
// 创建群
export const  qunCreate =(data) =>{
    return request({
        url: "/adminservice/qun/create",
        method:"post",
        data: data
    })
}
// 查询客户群组
export const  queryCustomQun =(query) =>{
    return request({
        url: "/adminservice/qun/queryCustomQun",
        method:"get",
        params: query
    })
}
// 修改群
export const  qunUpdate =(data) =>{
    return request({
        url: "/adminservice/qun/update",
        method:"post",
        data: data
    })
}
// 查询群详细信息
export const  queryQunInfo =(query) =>{
    return request({
        url: "/authservice/admin/queryQunInfo",
        method:"get",
        params: query
    })
}
// 删除群成员
export const  qunDeleteMember =(query) =>{
    return request({
        url: "/adminservice/qun/deleteMember",
        method:"get",
        params: query
    })
}
// 单个添加群成员
export const  qunAddQunMember =(data) =>{
    return request({
        url: "/adminservice/qun/addQunMember",
        method:"post",
        data: data
    })
}
// 同意入群申请
export const  qunAgreeApply =(query) =>{
    return request({
        url: "/adminservice/qun/agreeApply",
        method:"get",
        params: query
    })
}
// 拒绝入群申请
export const  qunRefuseApply =(query) =>{
    return request({
        url: "/adminservice/qun/refuseApply",
        method:"get",
        params: query
    })
}
// 创建群云本
export const  qunAddQunRecord =(data) =>{
    return request({
        url: "/adminservice/qun/addQunRecord",
        method:"post",
        data: data
    })
}
// 创建群成员云本(选择成员)
export const  qunReissueMember =(data) =>{
    return request({
        url: "/adminservice/qun/reissueMember",
        method:"post",
        data: data
    })
}
// 配置群管理员
export const  qunConfigAdmin =(data) =>{
    return request({
        url: "/adminservice/qun/configAdmin",
        method:"post",
        data: data
    })
}
// 配置群视察员
export const  qunConfigViewer =(data) =>{
    return request({
        url: "/adminservice/qun/configViewer",
        method:"post",
        data: data
    })
}
// 批量创建群云本
export const  qunBatchCreateQunRecord =(data) =>{
    return request({
        url: "/adminservice/qun/batchCreateQunRecord",
        method:"post",
        data: data
    })
}
// 群停用或启用
export const  qunStopOrUse =(query) =>{
    return request({
        url: "/adminservice/qun/stopOrUse",
        method:"get",
        params: query
    })
}
// 新增群任务
export const  noteTaskCreate =(data) =>{
    return request({
        url: "/adminservice/note/task/create",
        method:"post",
        data: data
    })
}
// 群任务完成情况列表
export const  noteTaskFinishInfo =(query) =>{
    return request({
        url: "/adminservice/note/task/finishInfo",
        method:"get",
        params: query
    })
}
// 获取任务详情
export const  noteTaskInfo =(query) =>{
    return request({
        url: "/adminservice/note/task/info",
        method:"get",
        params: query
    })
}
// 查询群任务列表
export const  noteTaskList =(data) =>{
    return request({
        url: "/adminservice/note/task/list",
        method:"post",
        data: data
    })
}
// 修改群任务
export const  noteTaskModify =(data) =>{
    return request({
        url: "/adminservice/note/task/modify",
        method:"post",
        data: data
    })
}
// 获取用户群任务完成详情
export const  taskFinishDetail =(data) =>{
    return request({
        url: "/adminservice/note/task/finishDetail",
        method:"post",
        data: data
    })
}
// 查询待批阅列表
export const  noteTaskReviewList =(query) =>{
    return request({
        url: "/adminservice/note/task/qunTask/reviewList",
        method:"get",
        params: query
    })
}
// 批阅群任务
export const  taskQunTaskReview =(data) =>{
    return request({
        url: "/adminservice/note/task/qunTask/review",
        method:"post",
        data: data
    })
}