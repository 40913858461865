<template>
    <div class="app-container" @click="visible = false;">
        <el-card class="mb15">
            <el-row>
                <el-col :span="20" class="mb0">
                    <el-button v-if="role.includes(0) || role.includes(2) || role.includes(4)" type="primary"
                        @click="handleAdd">批量创建班级</el-button>
                    <el-button v-if="role.includes(0) || role.includes(2) || role.includes(4)" type="primary"
                        @click="handleXnAdd">新增虚拟班级</el-button>
                </el-col>
                <el-col :span="4" class="mr0 mb0">
                    <div class="pull-right">
                        <el-popover :visible="visible" popper-style="width: 320px !important;" placement="bottom"
                            :min-width="'auto'">
                            <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true"
                                label-width="68px">
                                <el-row class="">
                                    <el-col :span="24" class=" mr0" v-if="role.includes(0)">
                                        <el-form-item label="客户名称" prop="termId" class="w-100">
                                            <el-select clearable class="w-100" @change="changeCustomId"
                                                v-model="queryParams.customId" placeholder="请选择客户">
                                                <el-option v-for="item in customList" :key="item.customId"
                                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                                    :value="item.customId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class=" mr0"
                                        v-if="role.includes(0) || role.includes(1) || role.includes(2) || role.includes(3)">
                                        <el-form-item label="学校" prop="schoolId" class="w-100">
                                            <el-select class="w-100" clearable @change="changeSchoolId"
                                                v-model="queryParams.schoolId" placeholder="请选择学校">
                                                <el-option v-for="item in schoolList" :key="item.schoolId"
                                                    :label="item.schoolName" :value="item.schoolId" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class=" mr0">
                                        <el-form-item label="年级" prop="grade" class="w-100">
                                            <el-select class="w-100" clearable v-model="queryParams.grade"
                                                placeholder="请选择年级">
                                                <el-option v-for="item in gradeList" :key="item" :label="item"
                                                    :value="item" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" class=" mr0">
                                        <el-form-item label="虚拟班级" prop="isVirtual" class="w-100">
                                            <el-select class="w-100" clearable v-model="queryParams.isVirtual"
                                                placeholder="请选择虚拟班级">
                                                <el-option label="是" :value="1" />
                                                <el-option label="否" :value="0" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <div style="margin:0 auto;">
                                        <el-button @click="getList()" type="primary">查找</el-button>
                                    </div>
                                </el-row>
                            </el-form>
                            <template #reference>
                                <el-button type="primary" @click.stop="visible = true;">筛选条件</el-button>
                            </template>
                        </el-popover>
                    </div>
                </el-col>
            </el-row>
        </el-card>
        <el-card>
            <!-- <el-form v-show="isSxShow" :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
                <el-row class="">
                    <el-col :span="6" class=" mr0" v-if="role.includes(0)">
                        <el-form-item label="客户名称" prop="termId">
                            <el-select clearable @change="changeCustomId" v-model="queryParams.customId"
                                placeholder="请选择客户">
                                <el-option v-for="item in customList" :key="item.customId"
                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class=" mr0"
                        v-if="role.includes(0) || role.includes(1) || role.includes(2) || role.includes(3)">
                        <el-form-item label="学校" prop="schoolId">
                            <el-select style="width: 100%;" clearable @change="changeSchoolId"
                                v-model="queryParams.schoolId" placeholder="请选择学校">
                                <el-option v-for="item in schoolList" :key="item.schoolId" :label="item.schoolName"
                                    :value="item.schoolId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class=" mr0">
                        <el-form-item label="年级" prop="grade">
                            <el-select style="width: 100%;" clearable @change="getList" v-model="queryParams.grade"
                                placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item" :label="item" :value="item" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class=" mr0">
                        <el-form-item label="虚拟班级" prop="isVirtual">
                            <el-select style="width: 100%;" clearable @change="getList" v-model="queryParams.isVirtual"
                                placeholder="请选择虚拟班级">
                                <el-option label="是" :value="1" />
                                <el-option label="否" :value="0" />
                            </el-select>
                        </el-form-item>
                    </el-col>

                </el-row>
            </el-form>
            <el-divider style="position: relative;" v-if="isSxShow">
                <img @click="isSxShow = false;"
                    style="transform: translateY(-100%);" class="sxShowcss"
                    src="../../assets/img/arrow-up.png" alt="">
            </el-divider> -->
            <div class="bre9e" style="position: relative;">
                <!-- <img @click="isSxShow = true;" v-if="!isSxShow"
                    style="transform: translateY(-36px);" class="sxShowcss"
                    src="../../assets/img/arrow-down.png" alt=""> -->
                <el-table header-cell-class-name="bgf2" highlight-current-row class="sssstt"
                    :height="'calc(100vh - ' + (isSxShow ? '255' : '265') + 'px)'" v-loading="loading" :data="dataList">
                    <el-table-column align="center" type="index" width="60" prop="studentNo" label="序号">
                    </el-table-column>
                    <el-table-column align="center" width="300" label="班级名称" prop="className">
                        <template #default="scope">
                            <div :title="scope.row.className"
                                :style="(role.includes(0) || role.includes(2) || role.includes(4)) ? 'color: #409efe;' : ''"
                                class="text-one cursor" @click="goDetail(scope.row)">
                                {{ scope.row.className }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="80" label="虚拟班级" prop="isVirtual">
                        <template #default="scope">
                            <div v-if="scope.row.isVirtual == 1">
                                是
                            </div>
                            <div v-else>否</div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column align="center" width="90" label="年级" prop="grade" /> -->
                    <el-table-column align="center" width="120" label="班主任账号" prop="username">
                        <template #default="scope">
                            <div v-if="scope.row.username && scope.row.isVirtual != 1">
                                {{ scope.row.username.substr(0, 3) + ' ' + scope.row.username.substr(3, 4) + ' ' +
                                    scope.row.username.substr(7, 4) }}
                            </div>
                            <div v-else></div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="110" label="班主任姓名" prop="teacherName">
                        <template #default="scope">
                            <div v-if="scope.row.isVirtual != 1" :title="scope.row.teacherName" class="text-one">
                                {{ scope.row.teacherName }}
                            </div>
                            <div v-else></div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="所属客户" prop="customName">
                        <template #default="scope">
                            <div :title="scope.row.customName" class="text-one">
                                {{ scope.row.customName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="所属学校" prop="schoolName">
                        <template #default="scope">
                            <div :title="scope.row.schoolName" class="text-one">
                                {{ scope.row.schoolName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="90" label="教师" prop="teacherList">
                        <template #default="scope">
                            <div v-if="scope.row.isVirtual != 1" :title="scope.row.className" style="color: #409efe;"
                                class="cursor" @click="goTeacher(scope.row)">
                                查看详情
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="90" label="学生" prop="studentList">
                        <template #default="scope">
                            <div :title="scope.row.className" style="color: #409efe;" class="cursor"
                                @click="goStudent(scope.row)">
                                查看详情
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column align="center" fixed="right" width="150" label="编辑"
                        v-if="role.includes(0) || role.includes(2) || role.includes(4)"
                        class-name="small-padding fixed-width">
                        <template #default="scope">
                            <div style="display: flex;" class="disnone">
                                <el-button link icon="Edit" type="primary" @click="handleUpdate(scope.row)">修改
                                </el-button>
                                <el-popconfirm confirm-button-text="是" cancel-button-text="否" :icon="InfoFilled"
                                    icon-color="#626AEF" title="确定删除该班级" @confirm="handleDelete(scope.row, scope.$index)">
                                    <template #reference>
                                        <el-button link icon="Delete" type="danger">删除
                                        </el-button>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="150" fixed="right" label="操作"
                        v-if="role.includes(0) || role.includes(2) || role.includes(4)"
                        class-name="small-padding fixed-width">
                        <template #default="scope">
                            <el-button type="primary" v-if="scope.row.isVirtual == 0"
                                @click="goFictitious(scope.row, 1)">设为虚拟班级
                            </el-button>
                            <el-button style="background: #359af2;color:#fff;" type="primary"
                                v-if="scope.row.isVirtual == 1" @click="goFictitious(scope.row, 0)">撤销虚拟班级
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

            </div>
            <!-- <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize" @pagination="getList" /> -->
        </el-card>
        <el-dialog :title="'查看教师'" v-model="isTeacherShow" width="60%">
            <el-table border :data="teacherList" style="width: 100%">
                <el-table-column align="center" type="index" width="60" prop="studentNo" label="序号">
                </el-table-column>
                <el-table-column align="center" width="" prop="teacherName" label="姓名">
                    <template #default="scope">
                        <div style="display: flex;align-items: center;">
                            {{ scope.row.teacherName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" width="140" prop="adminAccount" label="账号">
                    <template #default="scope">
                        <div>{{ scope.row.username.substr(0, 3) + ' ' +
                            scope.row.username.substr(3, 4) + ' ' +
                            scope.row.username.substr(7, 4) }}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" width="" prop="gender" label="性别">
                    <template #default="scope">
                        <div style="" v-if="scope.row.gender || scope.row.gender == 0">
                            {{ scope.row.gender == 0 ? '男' : '女' }}
                        </div>
                        <div v-else>

                        </div>

                    </template>
                </el-table-column>
                <el-table-column align="center" prop="subjectTxt" label="学科">
                </el-table-column>
                <el-table-column align="center" fixed="right" width="120" label="操作" class-name="small-padding fixed-width"
                    v-if="role.includes(0) || role.includes(2) || role.includes(4)">
                    <template #default="scope">
                        <el-button v-if="activeRow.leadTeacherId != scope.row.teacherId" size="mini" type="text"
                            @click="handleBzr(scope.row)">设置为班主任
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog :title="'查看学生'" v-if="isStudentShow" v-model="isStudentShow" width="80%">
            <div class="" style="height: 50vh;border: 1px solid rgb(239 236 236);overflow:auto;">
                <div v-for="item in (rowTol)"
                    :style="`display: flex;align-items: center;height: 60px;width:${100 * (colTol + 1)}px;`">
                    <div :style="`width:100px;`">
                        <div style="width: 80%;margin:0 auto;text-align:center;">排{{ (rowTol + 1) - item }}
                        </div>
                    </div>
                    <div :style="`width:100px;height:100%`" v-for="items in colTol">
                        <div :class="changeGender((rowTol + 1) - item, items) == 0 ? 'bg0798f0' : 'bgf24d52'"
                            v-if="changeStudent((rowTol + 1) - item, items)"
                            style="width: 80%;height:80%;margin:10% auto;border: 1px solid #e3e3e3;position: relative;cursor: pointer;background:#f5f5f5;text-align: center;">
                            <div style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);width: 90%;">
                                {{ changeStudent((rowTol + 1) - item, items) }}</div>
                        </div>
                        <div v-else
                            style="width: 80%;height:80%;margin:10% auto;border: 1px solid #e3e3e3;position: relative;cursor: pointer;text-align: center;">
                            <div style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);width: 90%;">
                                {{ changeStudent((rowTol + 1) - item, items) }}</div>
                        </div>
                    </div>
                </div>
                <div :style="`display: flex;align-items: center;height: 30px;width:${100 * (colTol + 1)}px;`">
                    <div :style="`width:100px;`">
                    </div>
                    <div :style="`width:100px;height:100%`" v-for="items in colTol">
                        <div style="text-align: center;margin-top:10%;">组{{ items }}</div>
                    </div>
                </div>
                <!-- <div :style="`display: flex;align-items: center;height: ${100 / (colTol + 1) / 2}%;`">
                    <div :style="`width:${100 / colTol}%;`">
                    </div>
                    <div style="width: 100%;height: 100%;">
                        <div
                            style="width: 20%;text-align: center;margin: 0 auto;padding: 10px;background: rgb(226, 149, 17);color: #333;">
                            讲台</div>
                    </div>
                </div> -->
            </div>
        </el-dialog>
        <!-- <el-dialog :title=" v-model="isTeach" width="700px">
        </el-dialog> -->
    </div>
</template>

<script>
import { bookList } from "@/api/adminservice/public";
import { timestampToTime } from "@/utils/date";
import { queryCustoms, stopCustoms, delCustoms, queryTreeCustomOrg } from "@/api/adminservice/custom";
import { queryCustomQun } from "@/api/adminservice/qun";
import { download } from "@/api/adminservice/public";
import { schoolUserAuthCondition, zhktQueryDict, schoolList, schoolListAuth, classDelete, classList, classInfo, classUpdate } from "@/api/adminservice/classOrganizaMain";

export default {
    name: "classManagement",
    components: {
    },
    data() {
        return {
            visible: false,
            isSxShow: true,
            activeRow: {},
            colTol: 9,
            rowTol: 9,
            classIsmList: [],
            gradeList: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级', '初一年级', '初二年级', '初三年级', '高一年级', '高二年级', '高三年级'],
            token: this.$storage.get("token"),
            isSuperAuth: this.$storage.get('isSuperAuth'),
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            readBook: false,
            readBookList: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            taskdata: {},
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                customId: '',
                gradeClassIsm: '',
                schoolId: '',
                grade: '',
                isVirtual: ''
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" }
                ],
                values: [
                    { required: true, message: "请输入内容", trigger: "blur" }
                ]
            },
            orgList: [],
            schoolList: [],
            classList: [],
            customList: [],
            teacherList: [],
            studentList: [],
            isTeacherShow: false,
            isStudentShow: false,
            subjectList: [],
            role: '',
        };
    },
    created() {
        this.role = this.$storage.get("role")
        schoolUserAuthCondition().then(res => {
            // this.schoolUserList = res.data.data;
            if (!this.role.includes(0) && !this.role.includes(1) && !this.role.includes(2) && !this.role.includes(3)) {
                this.classList = res.data.data.classList;
                this.getList()
            }
        })
        this.getZhktQueryDict();
    },
    activated() {
        // console.log('fdssfd',this.$route.query.isSx)
        if (this.$route.query.isSx == 1) {
            this.getList();
            if (this.$route.query.customId) {
                this.queryParams.customId = this.$route.query.customId
                this.changeCustom()
            } else {
            }
        }
    },
    mounted() {
        if (this.$route.query.customId) {
            this.queryParams.customId = this.$route.query.customId
            this.changeCustom()
        } else {
        }

        this.getBookList();

    },
    methods: {
        handleBzr(row) {
            this.activeRow.leadTeacherId = row.teacherId
            classUpdate(this.activeRow).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("设置成功");
                    this.getList()
                } else {
                    this.$message.error("操作失败")
                }
            });
        },
        changeStudent(row, col) {
            for (var i = 0; i < this.studentList.length; i++) {
                var e = this.studentList[i]
                if (e.seatCol == col && e.seatRow == row) {
                    console.log(e.studentName);
                    return e.studentName + '(' + e.dotNo + ')';
                }
            }
        },
        changeGender(row, col) {
            for (var i = 0; i < this.studentList.length; i++) {
                var e = this.studentList[i]
                if (e.seatCol == col && e.seatRow == row) {
                    return e.gender;
                }
            }
        },
        goTeacher(row) {
            classInfo({ classId: row.classId }).then(res => {
                res.data.data.teacherList.forEach(e => {
                    e.subjectList = e.subject.split(',');
                    var subjectTxt = [];
                    e.subjectList.forEach(j => {
                        this.subjectList.forEach(m => {
                            if (j == m.dictKey) {
                                subjectTxt.push(m.dictValue)
                            }
                        })
                    })
                    e.subjectTxt = subjectTxt.toString('、')
                })
                this.teacherList = res.data.data.teacherList;
                this.activeRow = res.data.data;
                this.isTeacherShow = true;
            })
        },
        goFictitious(row, type) {
            console.log(row);
            classInfo({ classId: row.classId }).then(res => {
                this.activeRow = res.data.data;
                this.activeRow.isVirtual = type;
                if (!this.activeRow.leadTeacherId) {
                    this.$message.error("请先选择班主任")
                    return;
                }
                classUpdate(this.activeRow).then(response => {
                    if (response.data.code == 1) {
                        this.$message.success("设置成功");
                        if(row.isVirtual == 1){
                            row.isVirtual = 0 
                        }else{
                            row.isVirtual = 1 
                        }
                        // this.getList()
                    } else {
                        this.$message.error("操作失败")
                    }
                });
            })
        },
        goStudent(row) {
            classInfo({ classId: row.classId }).then(res => {
                this.studentList = res.data.data.studentList;
                this.isStudentShow = true;
                this.colTol = res.data.data.groupNum;
                this.rowTol = res.data.data.rowNum;
            })
        },
        changeCustomId() {
            this.queryParams.schoolId = '';
            this.queryParams.grade = '';
            this.gradeList = ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级', '初一年级', '初二年级', '初三年级', '高一年级', '高二年级', '高三年级']
            schoolListAuth({ customId: this.queryParams.customId ? this.queryParams.customId : this.$storage.get('customId'), adminUserId: (this.role.includes(3) || this.role.includes(2) || this.role.includes(1)) ? JSON.parse(this.$storage.get('userInfo')).userId : '' }).then(res => {
                this.schoolList = res.data.data;
                // this.getList();
            });
        },
        changeSchoolId() {
            this.queryParams.grade = '';
            this.gradeList = [];
            if (this.queryParams.schoolId == '') {
                this.gradeList = ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级', '初一年级', '初二年级', '初三年级', '高一年级', '高二年级', '高三年级'];
            }
            this.schoolList.forEach(e => {
                if (e.schoolId == this.queryParams.schoolId) {
                    console.log(e);
                    if (e.gradeClassIsm) {
                        var gradeClassIsmList = e.gradeClassIsm.split(',')
                        if (e.gradeClassIsm)
                            if (gradeClassIsmList.indexOf('1') != -1) {
                                this.gradeList.push('一年级')
                                this.gradeList.push('二年级')
                                this.gradeList.push('三年级')
                                this.gradeList.push('四年级')
                                this.gradeList.push('五年级')
                                this.gradeList.push('六年级')
                            }
                        if (gradeClassIsmList.indexOf('2') != -1) {
                            this.gradeList.push('初一年级')
                            this.gradeList.push('初二年级')
                            this.gradeList.push('初三年级')
                        }
                        if (gradeClassIsmList.indexOf('3') != -1) {
                            this.gradeList.push('高一年级')
                            this.gradeList.push('高二年级')
                            this.gradeList.push('高三年级')
                        }
                    }

                }
            })
            // this.getList();
        },
        getZhktQueryDict() {
            zhktQueryDict({ code: 'subject' }).then(res => {
                this.subjectList = res.data.data;
            })
            zhktQueryDict({ code: 'grade_class_ism' }).then(res => {
                this.classIsmList = res.data.data;
            })
            this.customList = []
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customType.includes('020');
                })
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
                this.customList.forEach((e, i) => {
                    if (e.customName == '南京孜博汇信息科技有限公司') {
                        this.customList.splice(i, 1)
                        this.customList.unshift(e)
                    }
                })
            });
            schoolListAuth({ customId: this.$storage.get('customId'), adminUserId: ((this.role.includes(3) || this.role.includes(2)) && !this.role.includes(1)) ? JSON.parse(this.$storage.get('userInfo')).userId : '' }).then(res => {
                this.schoolList = res.data.data;
                this.getList();
            });
        },
        goDetail(row) {
            if (this.role.includes(0) || this.role.includes(2) || this.role.includes(4)) {
                this.$router.push({
                    path: "/classManagementDetail",
                    query: {
                        classId: row.classId,
                        isNoSet: 1,
                    }
                });
            }
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            // if (file.url) {
            //     delLessonFile({ fileUrl: file.url })
            // }
            // if (file.response) {
            //     delLessonFile({ fileUrl: file.response.data })
            // }
        },
        handleChange(e, type) {
            console.log(e, type)
            if (e.response) {
                if (e.response.code == 1) {
                    this.$message.success('导入成功')
                    this.getList()
                } else {
                    if (e.response.msg) {
                        this.$message.error(e.response.msg)
                    } else {
                        this.$message.error('导入失败')
                    }
                }
            }
            if (e.status == 'fail') {
                this.$message.error('导入失败')
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgName
                        };
                        parentIds = [item.orgName];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgName);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgName
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            // console.log(parentIds)
            return parentIds

        },
        changeCustom() {
            queryTreeCustomOrg({ customId: this.queryParams.customId }).then(res => {

                this.orgList = res.data.data;
                // console.log(4343,this.findParentNode('f66df71c0ee9425d920132c40b7bba2e'))

                queryCustomQun({ customId: this.queryParams.customId }).then(res => {
                    res.data.data.forEach(e => {
                        console.log(e.orgId)

                        console.log(this.findParentNode(e.orgId))

                        e.orgName = this.findParentNode(e.orgId).join(' -> ')
                        // console.log(434,this.findParentNode(e.orgId))
                    })
                    this.dataList = res.data.data;

                })
            })
        },
        readbookIds(row) {
            this.readBookList = [];
            this.bookList.forEach(e => {
                row.bookIds.forEach(j => {
                    if (e.id == j) {
                        this.readBookList.push(e)
                    }
                })
            })
            this.readBook = true;
        },
        getBookList() {
            bookList().then(res => {
                this.bookList = res.data.data;
            });
        },
        tgMethod(e) {
            this.queryParams.customName = '';
            this.open = false;
            this.taskdata = {};
            this.getList();
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.dataList = []
            this.loading = true;
            classList({ customId: this.$storage.get('customId'), schoolId: this.queryParams.schoolId ? this.queryParams.schoolId : '' }).then(res => {
                this.loading = false;
                if (this.queryParams.customId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.customId.toString().indexOf(this.queryParams.customId) != -1
                    })
                }
                if (this.queryParams.schoolId !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.schoolId.toString().indexOf(this.queryParams.schoolId) != -1
                    })
                }
                if (this.queryParams.grade !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        if (itemA.grade) {
                            return itemA.grade.toString() == (this.queryParams.grade)
                        }
                    })
                }
                if (this.queryParams.isVirtual !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.isVirtual == this.queryParams.isVirtual
                    })
                }

                if (!this.role.includes(0) && !this.role.includes(1) && !this.role.includes(2) && !this.role.includes(3)) {
                    this.dataList = []
                    res.data.data.forEach(e => {
                        this.classList.forEach(j => {
                            if (e.classId == j.classId) {
                                this.dataList.push(e)
                            }
                        })
                    })
                } else if (!this.role.includes(0) && !this.role.includes(1) && (this.role.includes(2) || this.role.includes(3))) {
                    this.dataList = []
                    res.data.data.forEach(e => {
                        this.schoolList.forEach(j => {
                            if (e.schoolId == j.schoolId) {
                                this.dataList.push(e)
                            }
                        })
                    })
                } else {
                    this.dataList = res.data.data;
                }


                // this.dataList.forEach(e => {
                //     e.gradeClassIsmList = e.gradeClassIsm && e.gradeClassIsm.split(',')
                //     console.log(e.gradeClassIsmList);
                //     var gragradeClassIsmTxt = [];
                //     e.gradeClassIsmList.forEach(j => {
                //         this.classIsmList.forEach(m => {
                //             if (j == m.dictKey) {
                //                 gragradeClassIsmTxt.push(m.dictValue)
                //             }
                //         })
                //     })
                //     e.gradeClassIsmTxt = gragradeClassIsmTxt.toString(',')
                // })

                if (this.queryParams.gradeClassIsm !== '') {
                    this.dataList = this.dataList.filter(itemA => {
                        return itemA.gradeClassIsmList.indexOf(this.queryParams.gradeClassIsm) != -1
                    })
                }
                // if (!this.$route.query.customId) {
                //     this.queryParams.customId = res.data.data[0].customId;
                //     this.changeCustom()
                // } else {
                // }
            });
        },

        // 停用
        handleStop(row) {
            stopCustoms({ customId: row.customId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("停用成功");
                    this.getList();
                } else {
                    this.$message.error("停用失败");
                }
            })
        },
        /** 删除按钮操作 */
        handleDelete(row, index) {
            classDelete({ classId: row.classId }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success("删除成功");
                    this.dataList.splice(index, 1)
                    // this.getList();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },

        /** 导出按钮操作 */
        handleExport() {
        },
        // 表单重置
        reset() {
            this.form = {
                did: null,
                type: null,
                title: null,
                values: null,
                px: null,
                createTime: null,
                updateTime: null,
                delFlag: null
            };
            // this.$refs['form'].resetFields()
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.$refs['queryForm'].resetFields()
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.did);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.$router.push({
                path: "/classManagementBatchAdd",
                query: {
                    // customId: this.queryParams.customId,
                }
            });
            // this.reset();
            // this.open = true;
            // this.form.px = 10;
            // this.title = "添加基础：基数数据";
        },
        handleXnAdd() {
            this.$router.push({
                path: "/classManagementVirtualAdd",
                query: {
                    // customId: this.queryParams.customId,
                }
            });
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.$router.push({
                path: "/classManagementDetail",
                query: {
                    classId: row.classId,
                    isNoSet: 1,
                }
            });
            // this.$router.push({
            //     path: "/classManagementAdd",
            //     query: {
            //         classId: row.classId,
            //     }
            // });
            // this.taskdata = row;
            // this.open = true;
        }
    }
};
</script>
<style scoped>
.disnone /deep/ .el-upload-list {
    display: none;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.bgacc1d9 {
    background: #acc1d9 !important;
}

.bg0798f0 {
    background: #e1f1f9 !important;
}

.bgf24d52 {
    background: #fed4d4 !important;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.sssstt /deep/ .el-scrollbar__view {
    display: block !important;
}
</style>