<template>
    <div class="app-container pt0Im pl0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>任务管理</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!taskId">新增群任务</el-breadcrumb-item>
                <el-breadcrumb-item v-if="taskId">编辑群任务</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 180px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="110px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="任务标题" prop="taskTitle">
                            <el-input v-model="form.taskTitle" placeholder="最多64个字" maxlength="64" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="任务起止时间" prop="formTime">
                            <el-col :span="11" class="date_picker">
                                <el-date-picker format="YYYY-MM-DD HH:mm" @change="startDateConfirm"
                                    :disabled-date="disabledDate" style="width: 100%;" v-model="form.beginTime"
                                    type="datetime" placeholder="开始时间" value-format="x" />
                            </el-col>
                            <el-col :span="2" class="text-center">
                                <span class="text-gray-500">至</span>
                            </el-col>
                            <el-col :span="11">
                                <el-date-picker format="YYYY-MM-DD HH:mm" class="date_picker" @change="endDateConfirm"
                                    :disabled-date="enddisabledFormDate" style="width: 100%;" v-model="form.endTime"
                                    type="datetime" placeholder="结束时间" value-format="x" />
                            </el-col>
                        </el-form-item></el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="任务内容" prop="taskContent ">
                            <el-input v-model="form.taskContent" :rows="4" type="textarea" placeholder="请输入任务内容" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="归属客户" prop="customId ">
                            <el-select class="w-100" @change="changeCustom" v-model="form.customId" placeholder="请选择客户">
                                <el-option v-for="item in customList" :key="item.customId"
                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-col :span="12">
                    <el-form-item label="群组" class="w-100" prop="qunId">
                        <el-select class="w-100" v-model="form.qunId" @change="changeQunId" placeholder="请选择群组">
                            <el-option v-for="item in qunList" :key="item.id" :label="item.qunName" :value="item.id" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-row>
                    <el-col>
                        <el-form-item label="指定云本" prop="qunRecordId">
                            <el-col class="custom">
                                <el-link type="primary" @click="openBook" :underline="false">云本配置</el-link>
                                <div>
                                    <div class="fl-warp" style="display: flex;">
                                        <div v-for="item in bookList" class="csbbb"
                                            :style="form.qunRecordIds.indexOf(item.id) != -1 ? '' : 'display:none'">
                                            <el-icon @click="delBookId(item.id)" class="eicon" style="cursor: pointer;">
                                                <Delete />
                                            </el-icon>
                                            <img style="width: 100px;margin-top: 10px;"
                                                :src="this.$store.state.zdy.imgUrl + '/book/' + item.resourceId + '/img/cover.png_original'"
                                                alt="" class="image">
                                            <div style="width: 100px;text-align: center;" class="text-one mb15">{{
                                                item.recordName }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <el-checkbox-group v-model="form.bookIds">
                                    <el-checkbox :label="item.id" v-for="item in bookList">
                                        <img style="width: 100px;margin-top: 10px;"
                                            :src="this.$store.state.zdy.imgUrl+'/book/' + item.id + '/img/cover.png_original'"
                                            alt="" class="image">
                                        <div style="width: 100px;" class="text-one mb15">{{ item.bookName }}</div>
                                    </el-checkbox>
                                </el-checkbox-group> -->
                            </el-col>

                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="添加附件" prop="file">
                            <el-upload :auto-upload="false" ref="zdyupload" v-model:file-list="form.file" :limit="6"
                                :on-remove="handleRemove" multiple class="upload-demo" drag @change="handleChange" action=""
                                accept=".pdf">
                                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                                <div class="el-upload__text">
                                    拖到文件到此处或 <em>点击上传文件</em>
                                </div>
                                <template #tip>
                                    <div class="el-upload__tip">
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="管理员" prop="reviewUsersList">
                            <el-select multiple style="width: 100%;" clearable v-model="form.reviewUsersList"
                                placeholder="请选择管理员">
                                <el-option v-for="item in userList" :key="item.userId"
                                    :label="item.managerName + '-' + item.username" :value="item.userId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:64px;' : 'left:201px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
        <el-dialog :title="'指定云本'" v-model="chooseBook" width="670px">
            <el-col class="custom ylsb" style="">
                <div v-for="item in bookList" :style="form.qunRecordIds.indexOf(item.id) != -1 ? 'display:none' : ''">
                    <div class="ylqs" @click="setChooseBook(item.id)"
                        :style="chooseBookIds.indexOf(item.id) != -1 ? 'background: #a3d6ff' : ''">
                        <img style="width: 100px;margin-top: 10px;"
                            :src="this.$store.state.zdy.imgUrl + '/book/' + item.resourceId + '/img/cover.png_original'"
                            alt="" class="image">
                        <div style="width: 100px;" class="text-one mb15">{{ item.recordName }}</div>
                    </div>
                </div>
            </el-col>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitChooseBookForm">确 定</el-button>
                    <el-button @click="chooseBookCancel">取 消</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { bookList } from "@/api/adminservice/public"
import { sceneInfo } from "@/api/adminservice/scene"
import { noteBookQunBookList } from "@/api/adminservice/book";
import { queryCustoms, queryTreeCustomOrg, createCustoms, custominfo, configCustom, customOrgBook } from "@/api/adminservice/custom"
import { qunQueryUser, qunCreate, qunUpdate, queryCustomQun, noteTaskCreate, noteTaskInfo, noteTaskModify } from "@/api/adminservice/qun"
import { formCreate, formInfo, updateForm } from "@/api/adminservice/form"
import { queryScene } from "@/api/adminservice/scene"
import { ElLoading } from 'element-plus'


export default {
    name: "demoAdd",
    components: {},
    data() {
        return {
            delFileList: [],
            userList: [],
            fileList: [],
            taskId: '',
            groupData: {},
            id: '',
            readBook: false,
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            qunList: [],
            // 表单参数
            form: {
                taskTitle: '',
                beginTime: '',
                endTime: '',
                taskContent: '',
                qunId: '',
                qunRecordIds: [],
                file: [],
                reviewUsersList: [],
            },
            sceneImgList: [],
            sceneVideoList: [],
            tableData: [{
                adminAccount: '',
                orgName: ''
            }],
            loadingInstance: null,
            // 表单校验
            rules: {
                taskTitle: [
                    { required: true, message: "请输入任务标题", trigger: "blur" },
                ],
                formTime: [
                    { required: true, validator: this.checkValidator }
                ],
                taskContent: [
                    { required: true, message: "请输入任务内容", trigger: "blur" },
                ],
                qunId: [
                    { required: true, message: "请选择群组", trigger: "change" },
                ],
                reviewUsersList: [
                    { required: true, message: "请选择管理员", trigger: "change" },
                ],
            },
            bookList: [],
            configObj: {
                bookIds: [],
                orgId: ''
            },
            customList: [],
            orgList: [],
            oldBookIds: [],
            sceneList: [],
            chooseBookIds: [],
            chooseBook: false,
        };
    },
    created() {

    },
    mounted() {
        if (this.$route.query.id) {
            this.taskId = this.$route.query.id;
            this.form.qunTaskId = this.$route.query.id;
            this.form.customId = this.$route.query.customId;
            queryCustomQun({ customId: this.form.customId }).then(res => {
                this.qunList = res.data.data;
                noteTaskInfo({ taskId: this.taskId }).then(res => {
                    Object.assign(this.form, res.data.data)
                    this.changeQunId()
                    if (this.form.qunRecordId) {
                        this.form.qunRecordIds = [this.form.qunRecordId]
                    }
                    this.form.reviewUsersList = this.form.reviewUsers.split(',')
                    this.form.file = [];
                    this.form.fileList.forEach(e => {
                        this.form.file.push({ name: e.fileName, url: e.fileUrl, fileId: e.fileId })
                    })
                })
            })

        } else {
        }
        this.getCustomList();
    },
    computed: {
    },
    methods: {
        changeBusinessType(e) {
            if (this.form.businessType == 1) {
                if (this.form.sceneId) {
                    sceneInfo({ sceneId: this.form.sceneId }).then(res => {
                        this.sceneImgList = res.data.data.imgList;
                        this.sceneVideoList = res.data.data.videoList;
                    })
                }
            }
        },
        changeScene(e) {
            this.form.formImg = ''
            this.form.formVideo = ''
            this.sceneImgList = [];
            this.sceneVideoList = [];
            console.log(e);
            if (this.form.businessType == 1) {
                sceneInfo({ sceneId: this.form.sceneId }).then(res => {
                    if (res.data.data.imgList) {
                        this.sceneImgList = res.data.data.imgList;
                    } else {
                        this.sceneImgList = [];
                    }
                    if (res.data.data.videoList) {
                        this.sceneVideoList = res.data.data.videoList;
                    } else {
                        this.sceneVideoList = [];
                    }

                })
            }
        },
        onFormImg(e) {
            e === this.form.formImg ? (this.form.formImg = "") : (this.form.formImg = e);
        },
        onFormVideo(e) {
            e === this.form.formVideo ? (this.form.formVideo = "") : (this.form.formVideo = e);
        },
        handleChange(file, fileList) {
            console.log(fileList)
            console.log(fileList.length)

            // this.form.file = []
            // if (fileList[0]) {
            //     this.form.file.push(fileList[fileList.length - 1])
            //     this.form.fileName = fileList[fileList.length - 1].name.substring(0, fileList[fileList.length - 1].name.lastIndexOf("."));
            //     this.form.formName = fileList[fileList.length - 1].name.substring(0, fileList[fileList.length - 1].name.lastIndexOf("."));
            // }
            console.log(this.form.file)
        },
        changeOrg() {
            console.log(this.$refs['tsd'])
            console.log(this.oldBookIds);
            this.form.bookIds = [];
            customOrgBook({ orgId: this.form.orgId }).then(res => {
                this.bookList = res.data.data;
                res.data.data.forEach(e => {
                    this.oldBookIds.forEach(j => {
                        if (e.id == j) {
                            this.form.bookIds.push(e.id)
                        }
                    })
                })
            })
        },
        validateUsername(rule, value, callback) {
            qunQueryUser({ mobile: value }).then(res => {
                const result = res.data.data
                if (!result) {
                    callback(new Error("该账号用户不存在"))
                } else {
                    this.form.adminName = res.data.data.nickName;
                    this.form.adminUserId = res.data.data.userId;

                    callback()
                }
            })
        },
        changeCustom() {
            this.orgList = [];
            this.sceneList = [];
            this.form.sceneId = '';
            this.form.orgId = '';
            queryCustomQun({ customId: this.form.customId }).then(res => {
                console.log(res.data.data)
                this.qunList = res.data.data;
            })
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.fileId) {
                this.delFileList.push(file.fileId)
            }
        },
        changeQunId() {
            this.userList = [];
            this.bookList = [];
            noteBookQunBookList({ qunId: this.form.qunId }).then(res => {
                this.bookList = res.data.data;
            })
            if (this.form.qunId) {
                this.qunList.forEach(e => {
                    if (e.id == this.form.qunId) {
                        this.userList = e.adminList
                    }
                })
            }
        },
        submitConfigForm() {
            console.log(this.configObj)
            configCustom(this.configObj).then(res => {
                if (res.data.code == 1) {
                    this.tableData.forEach(e => {
                        if (e.orgId) {
                            if (e.orgId == this.configObj.orgId) {
                                e.bookIds = this.configObj.bookIds
                            }
                        }
                    })
                    this.$message.success("配置成功");
                    this.addPeopleOpen = false;
                    this.configCancel();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        configCancel() {
            this.readBook = false;
            this.configObj.bookIds = [];
            this.configObj.orgId = '';
        },
        configurationRow(index, row) {
            if (row.adminAccount == '') {
                this.$message.error("请填写管理员账号")
                return;
            }
            if (row.orgName == '') {
                this.$message.error("请填写组织名称")
                return;
            }
            this.readBook = true;
            this.configObj.bookIds = row.bookIds;
            this.configObj.orgId = row.orgId;
            console.log(index, row)
        },
        deleteRow(index) {
            console.log(index)
            if (this.tableData.length == 1) {
                this.$message.error("不能全部删除")
            } else {
                this.tableData.splice(index, 1)
            }
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                adminAccount: '',
                orgName: ''
            })
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginDate) {
                return current && current < this.form.beginDate
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        /** 查询基础：基数数据列表 */
        getCustomList() {
            this.loading = false;
            this.customList = []
            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customType.includes('030');
                })
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
            });
        },
        delBookId(id) {
            this.form.qunRecordIds.splice(this.form.qunRecordIds.indexOf(id), 1)
        },
        setChooseBook(id) {
            this.chooseBookIds = [id]
        },
        chooseBookCancel() {
            this.chooseBook = false;
        },
        submitChooseBookForm() {
            this.form.qunRecordIds = this.chooseBookIds
            this.chooseBook = false;
        },
        openBook() {
            if (this.$storage.get('role').includes(1)) {
                var st = Number(this.$storage.get('030StTime'))
                var end = Number(this.$storage.get('030EdTime'))
                var nT = new Date().getTime();
                if (nT < st || nT > end) {
                    this.$message.error('当前客户没有权限')
                    return
                }
                if (end < (nT + 2592000000)) {
                    this.$message.warning('当前客户权限快过期')
                }
            }
            this.chooseBookIds = [];
            this.chooseBook = true;
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            this.form.isBook = 0;
            this.$refs["form"].validate(valid => {
                if (valid) {
                    this.loadingInstance = ElLoading.service({
                        lock: true,
                        text: '提交中',
                        background: 'rgba(0, 0, 0, 0.7)',
                    })
                    let formdata = new FormData();
                    this.form.file.map((item) => {
                        console.log(item)
                        if(!item.fileId){
                            formdata.append('files', item.raw)
                        }
                    })
                    formdata.append('beginTime', this.form.beginTime)
                    formdata.append('endTime', this.form.endTime)
                    formdata.append('finishCanImage', 1)
                    formdata.append('qunId', this.form.qunId)
                    if (this.form.qunRecordIds.length != 0) {
                        formdata.append('qunRecordId', this.form.qunRecordIds[0])
                    }
                    formdata.append('reviewUsers', this.form.reviewUsersList.toString())
                    formdata.append('taskContent', this.form.taskContent)
                    formdata.append('taskTitle', this.form.taskTitle)
                    if (this.taskId) {
                        formdata.append('fileIds', this.delFileList.toString())
                        formdata.append('qunTaskId', this.taskId)
                        noteTaskModify(formdata).then(res => {
                            if (res.data.code == 1) {
                                this.loadingInstance.close()
                                this.$message.success("修改成功");
                                this.replace('/noteTask', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.loadingInstance.close()
                                this.$message.error("操作失败")
                            }
                        }).catch(err => {
                            this.loadingInstance.close()
                        });
                    } else {
                        noteTaskCreate(formdata).then(res => {
                            if (res.data.code == 1) {
                                this.loadingInstance.close()
                                this.$message.success("新增成功");
                                this.replace('/noteTask', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.loadingInstance.close()
                                this.$message.error("操作失败")
                            }

                        }).catch(err => {
                            this.loadingInstance.close()
                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/noteTask')
            // this.$emit('isTg', true)
        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {

                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId
                    console.log(this.findParentNode(e.orgId))
                    if (this.findParentNode(e.orgId).indexOf(2) == -1) {
                        e.disabled = true
                    }
                    if (e.orgType == 5) {
                        // debugger
                        e.disabled = true
                        console.log(e)
                    }
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList

                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    /* max-height: 250px;
    overflow-y: auto; */
}

.custom /deep/ .el-radio {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-radio-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-radio__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.csbbb:hover .eicon {
    display: block;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}
</style>