const state = {
    isActive:false,
    activeLineWidth: 2,
    activeColor: "#000000",
    scrollTop:50,
    // imgUrl:'http://test-oss-zbh.oss-cn-beijing.aliyuncs.com',
    imgUrl:'https://zbh-oss-formal.oss-cn-beijing.aliyuncs.com',
    // convasImgUrl:'http://47.93.86.37:8787/illustration/',
    convasImgUrl:'https://group.zbform.com/illustration/',
};

const mutations = {
    setIsActive: (state, n) => {
        state.isActive = n;
    },
    setScrollTop: (state, n) => {
        state.scrollTop = n;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
};