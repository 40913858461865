<template>
    <div class="app-container pt0Im pl0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>群组设置</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!customId">新增群组</el-breadcrumb-item>
                <el-breadcrumb-item v-if="customId">编辑群组</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 180px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="客户" prop="customId">
                            <el-select :disabled="role.includes(1)" clearable @change="changeCustom" v-model="form.customId"
                                placeholder="请选择客户">
                                <el-option v-for="item in customList" :key="item.customId"
                                    :label="((item.status == 1 ? '演示客户 | ' : '') + item.customName)"
                                    :value="item.customId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="组织" prop="orgId">
                            <el-tree-select ref="tsd" default-expand-all node-key="orgId" clearable @change="changeOrg"
                                v-model="form.orgId" check-strictly :data="orgList" placeholder="请选择组织" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="群名称" prop="qunName">
                            <el-input v-model="form.qunName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="群主账号" prop="adminAccount">
                            <el-input v-model="form.adminAccount" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="群主姓名" prop="adminName">
                            <el-input v-model="form.adminName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="管理员" prop="adminList">
                            <el-table ref="adminList" border :data="adminList" style="width: 600px" max-height="430">
                                <el-table-column align="center" type="index" label="序号" width="60" />
                                <el-table-column align="center" prop="username" label="管理员账号">
                                    <template #default="scope">
                                        <el-input @blur="validateAdminUsername(scope.row)" v-model="scope.row.username"
                                            placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="managerName" label="管理员姓名">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.managerName" placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" fixed="right" label="操作" width="160">
                                    <template #default="scope">
                                        <el-button link type="primary" size="small"
                                            @click.prevent="deleteAdminRow(scope.$index)">
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div style="width: 100%;">
                                <el-button plain type="primary" class="mt10" style="width: 600px"
                                    @click="onAdminAddItem">增加</el-button>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="视察员" prop="viewList">
                            <el-table ref="viewList" border :data="viewList" style="width: 600px" max-height="430">
                                <el-table-column align="center" type="index" label="序号" width="60" />
                                <el-table-column align="center" prop="username" label="视察员账号">
                                    <template #default="scope">
                                        <el-input @blur="validateViewUsername(scope.row)" v-model="scope.row.username"
                                            placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="managerName" label="视察员姓名">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.managerName" placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" fixed="right" label="操作" width="160">
                                    <template #default="scope">
                                        <el-button link type="primary" size="small"
                                            @click.prevent="deleteViewRow(scope.$index)">
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div style="width: 100%;">
                                <el-button plain type="primary" class="mt10" style="width: 600px"
                                    @click="onViewAddItem">增加</el-button>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="群成员" prop="memberList">
                            <el-table ref="memberList" border :data="memberList" style="width: 600px" max-height="430">
                                <el-table-column align="center" type="index" label="序号" width="60" />
                                <el-table-column align="center" prop="username" label="成员账号">
                                    <template #default="scope">
                                        <el-input @blur="validateMemberUsername(scope.row)" v-model="scope.row.username"
                                            placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="memberName" label="成员姓名">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.memberName" placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" fixed="right" label="操作" width="160">
                                    <template #default="scope">
                                        <el-button link type="primary" size="small"
                                            @click.prevent="deleteMemberRow(scope.$index)">
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div style="width: 100%;">
                                <el-button plain type="primary" class="mt10" style="width: 600px"
                                    @click="onMemberAddItem">增加</el-button>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="群笔记表单" prop="formIds">
                            <el-select style="width: 100%;" multiple filterable v-model="form.formIds"
                                placeholder="请选择群笔记表单">
                                <el-option v-for="item in formList" :key="item.formId" :label="item.formName"
                                    :value="item.formId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="云本配置" prop="bookIds">
                            <el-col class="custom">
                                <el-link type="primary" @click="openBook" :underline="false">资源配置</el-link>
                                <div>
                                    <div class="fl-warp" style="display: flex;">
                                        <div v-for="item in bookList" class="csbbb"
                                            :style="form.bookIds.indexOf(item.id) != -1 ? '' : 'display:none'">
                                            <el-icon @click="delBookId(item.id)" class="eicon" style="cursor: pointer;">
                                                <Delete />
                                            </el-icon>
                                            <img style="width: 100px;margin-top: 10px;"
                                                :src="this.$store.state.zdy.imgUrl + '/book/' + item.id + '/img/cover.png_original'"
                                                alt="" class="image">
                                            <div style="width: 100px;text-align: center;" class="text-one mb15">{{
                                                item.bookName }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <el-checkbox-group v-model="form.bookIds">
                                    <el-checkbox :label="item.id" v-for="item in bookList">
                                        <img style="width: 100px;margin-top: 10px;"
                                            :src="this.$store.state.zdy.imgUrl+'/book/' + item.id + '/img/cover.png_original'"
                                            alt="" class="image">
                                        <div style="width: 100px;" class="text-one mb15">{{ item.bookName }}</div>
                                    </el-checkbox>
                                </el-checkbox-group> -->
                            </el-col>

                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:64px;' : 'left:201px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
        <el-dialog :title="'配置云本'" v-model="chooseBook" width="670px">
            <el-col class="custom ylsb" style="">
                <div v-for="item in bookList" :style="form.bookIds.indexOf(item.id) != -1 ? 'display:none' : ''">
                    <div class="ylqs" @click="setChooseBook(item.id)"
                        :style="chooseBookIds.indexOf(item.id) != -1 ? 'background: #a3d6ff' : ''">
                        <img style="width: 100px;margin-top: 10px;"
                            :src="this.$store.state.zdy.imgUrl + '/book/' + item.id + '/img/cover.png_original'" alt=""
                            class="image">
                        <div style="width: 100px;" class="text-one mb15">{{ item.bookName }}</div>
                    </div>
                </div>
            </el-col>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitChooseBookForm">确 定</el-button>
                    <el-button @click="chooseBookCancel">取 消</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { formGroupForm } from "@/api/adminservice/form"
import { bookList } from "@/api/adminservice/public"
import { queryCustoms, queryTreeCustomOrg, createCustoms, custominfo, configCustom, customOrgBook } from "@/api/adminservice/custom"
import { qunQueryUser, qunCreate, qunUpdate, queryCustomQun, queryQunInfo } from "@/api/adminservice/qun"
import { teacherQueryOrRegUser } from "@/api/adminservice/classOrganizaMain"
import { validatenull, isMobile } from '@/utils/validate'

export default {
    name: "demoAdd",
    components: {},
    data() {
        return {
            adminList: [],
            viewList: [],
            memberList: [],
            formList: [],
            customId: '',
            groupData: {},
            id: '',
            readBook: false,
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                adminName: '',
                adminUserId: '',
                orgId: '',
                formIds: [],
            },
            tableData: [{
                adminAccount: '',
                orgName: ''
            }],
            // 表单校验
            rules: {
                orgId: [
                    { required: true, message: "请选择组织", trigger: "change" },
                ],
                qunName: [
                    { required: true, message: "请输入群名称", trigger: "blur" },
                ],
                adminAccount: [
                    { required: true, message: "请输入群主账号", trigger: "blur" },
                    { validator: this.validateUsername, trigger: "blur" }
                ],
                adminName: [
                    { required: true, message: "请输入群主名称", trigger: "blur" },
                ],
                bookIds: [
                    { required: true, message: "请选择资源库书本", trigger: "change" },
                ],
                adminList: [
                    { required: true, validator: this.checkAdminValidator }
                ],
                viewList: [
                    { required: true, validator: this.checkViewValidator }
                ],
                memberList: [
                    { required: true, validator: this.checkMemberValidator }
                ]
            },
            bookList: [],
            configObj: {
                bookIds: [],
                orgId: ''
            },
            customList: [],
            orgList: [],
            oldBookIds: [],
            oldFromIds: [],
            chooseBookIds: [],
            chooseBook: false,
            id: '',
            role: '',
        };
    },
    created() {
        this.role = this.$storage.get("role")
    },
    mounted() {
        if (this.$route.query.id) {
            this.customId = this.$route.query.customId;
            this.id = this.$route.query.id;
            this.getList();
            // this.form.checkUsersList = this.form.checkUsers.split(',')
            this.getInfo()

            queryTreeCustomOrg({ customId: this.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                this.orgList = res.data.data;
            })


        } else {
            if (this.$storage.get("role").includes(1)) {
                this.form.customId = this.$storage.get("customId")
                this.customId = this.$storage.get("customId")
            } else {
                this.customId = this.$route.query.customId;
                this.form.customId = this.$route.query.customId;
            }
            formGroupForm({ customId: this.form.customId }).then(res => {
                this.formList = res.data.data;
            })
            queryTreeCustomOrg({ customId: this.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                this.orgList = res.data.data;
            })
            this.getList();
        }

    },
    computed: {
    },
    methods: {
        checkAdminValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.adminList.length; i++) {
                var e = this.adminList[i];
                if (e.username == '' || e.managerName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        checkViewValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.viewList.length; i++) {
                var e = this.viewList[i];
                if (e.username == '' || e.managerName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        checkMemberValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.memberList.length; i++) {
                var e = this.memberList[i];
                if (e.username == '' || e.memberName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        getInfo() {
            queryQunInfo({ qunId: this.id }).then(res => {
                this.form = res.data.data;
                this.form.customId = this.$route.query.customId;
                this.adminList = res.data.data.adminList;
                this.viewList = res.data.data.viewList;
                this.memberList = res.data.data.memberList;
                this.oldBookIds = JSON.parse(JSON.stringify(res.data.data)).bookIds;
                this.oldFromIds = JSON.parse(JSON.stringify(res.data.data)).formIds;
                customOrgBook({ orgId: this.form.orgId }).then(res => {
                    this.bookList = res.data.data;
                })
                formGroupForm({ customId: this.form.customId }).then(res => {
                    this.formList = res.data.data;
                })
            })
        },
        delBookId(id) {
            this.form.bookIds.splice(this.form.bookIds.indexOf(id), 1)
        },
        setChooseBook(id) {
            if (this.chooseBookIds.indexOf(id) === -1) {
                this.chooseBookIds.push(id)
            } else {
                this.chooseBookIds.splice(this.chooseBookIds.indexOf(id), 1)
            }
        },
        chooseBookCancel() {
            this.chooseBook = false;
        },
        submitChooseBookForm() {
            this.form.bookIds = this.form.bookIds.concat(this.chooseBookIds)
            this.chooseBook = false;
        },
        openBook() {
            if (this.$storage.get('role').includes(1)) {
                var st = Number(this.$storage.get('030StTime'))
                var end = Number(this.$storage.get('030EdTime'))
                var nT = new Date().getTime();
                if (nT < st || nT > end) {
                    this.$message.error('当前客户没有权限')
                    return
                }
                if (end < (nT + 2592000000)) {
                    this.$message.warning('当前客户权限快过期')
                }
            }
            this.chooseBookIds = [];
            this.chooseBook = true;
        },
        changeOrg() {
            console.log(this.$refs['tsd'])
            console.log(this.oldBookIds);
            this.form.bookIds = [];
            console.log(this.form.orgId)
            customOrgBook({ orgId: this.form.orgId }).then(res => {
                this.bookList = res.data.data;
                res.data.data.forEach(e => {
                    this.oldBookIds.forEach(j => {
                        if (e.id == j) {
                            this.form.bookIds.push(e.id)
                        }
                    })
                })
            })
        },
        validateAdminUsername(value) {
            console.log(value);
            if (value.username && !isMobile(value.username)) {
                value.username = ''
                value.managerName = ''
                this.$message.error("手机号码格式不正确")
                return
            }
            if (this.form.adminAccount == value.username) {
                value.username = ''
                value.managerName = ''
                this.$message.error("不能和群主账号一样")
            } else {
                var isTg = true
                this.viewList.forEach(e => {
                    if (e.username == value.username) {
                        isTg = false;
                    }
                })
                if (isTg) {
                    teacherQueryOrRegUser({ username: value.username }).then(res => {
                        const result = res.data.data
                        if (!result) {
                            value.username = ''
                            value.managerName = ''
                            this.$message.error("请输入正确账号")
                        } else {
                            value.managerName = res.data.data.trueName ? res.data.data.trueName : res.data.data.username
                            value.userId = res.data.data.userId
                        }
                    })
                } else {
                    value.username = ''
                    value.managerName = ''
                    this.$message.error("该账号不能和视察员账号一样")
                }

            }
        },
        validateViewUsername(value) {
            console.log(value);
            if (value.username && !isMobile(value.username)) {
                value.username = ''
                value.managerName = ''
                this.$message.error("手机号码格式不正确")
                return
            }
            if (this.form.adminAccount == value.username) {
                value.username = ''
                value.managerName = ''
                this.$message.error("不能和群主账号一样")
            } else {
                var isTg = true
                this.adminList.forEach(e => {
                    if (e.username == value.username) {
                        isTg = false;
                    }
                })
                if (isTg) {
                    teacherQueryOrRegUser({ username: value.username }).then(res => {
                        const result = res.data.data
                        if (!result) {
                            value.username = ''
                            value.managerName = ''
                            this.$message.error("请输入正确账号")
                        } else {
                            value.managerName = res.data.data.trueName ? res.data.data.trueName : res.data.data.username
                            value.userId = res.data.data.userId
                        }
                    })
                } else {
                    value.username = ''
                    value.managerName = ''
                    this.$message.error("该账号不能和管理员账号一样")
                }

            }
        },
        validateMemberUsername(value) {
            if (value.username && !isMobile(value.username)) {
                value.username = ''
                value.managerName = ''
                this.$message.error("手机号码格式不正确")
                return
            }
            teacherQueryOrRegUser({ username: value.username }).then(res => {
                const result = res.data.data
                if (!result) {
                    value.username = ''
                    value.memberName = ''
                    this.$message.error("请输入正确账号")
                } else {
                    value.memberName = res.data.data.trueName ? res.data.data.trueName : res.data.data.username
                    value.userId = res.data.data.userId
                }
            })
        },
        validateUsername(rule, value, callback) {
            qunQueryUser({ mobile: value }).then(res => {
                const result = res.data.data
                if (!result) {
                    callback(new Error("该账号用户不存在"))
                } else {
                    var isTg = true
                    this.adminList.forEach(e => {
                        if (e.username == value) {
                            isTg = false;
                            callback(new Error("该账号不能和管理员账号一样"))
                            throw new Error();
                        }
                    })
                    this.viewList.forEach(e => {
                        if (e.username == value) {
                            isTg = false;
                            callback(new Error("该账号不能和视察员账号一样"))
                            throw new Error();
                        }
                    })
                    if (isTg) {
                        if (this.form.adminName == '') {
                            this.form.adminName = res.data.data.nickName;
                        }
                        this.form.adminUserId = res.data.data.userId;

                        callback()
                    }

                }
            })
        },
        changeCustom() {
            this.formList = [];
            this.orgList = [];
            this.form.orgId = '';
            this.form.formIds = [];
            queryTreeCustomOrg({ customId: this.form.customId }).then(res => {
                this.orgList = res.data.data;
                res.data.data.forEach((e, i) => {
                    e.label = e.orgName
                    e.value = e.orgId
                    e.children = e.childrenList;
                    e.disabled = true
                    this.ergodicList(e.childrenList)
                })
                console.log(res.data.data)
                this.orgList = res.data.data;
            })
            formGroupForm({ customId: this.form.customId }).then(res => {
                this.formList = res.data.data;
                res.data.data.forEach(e => {
                    this.oldFromIds.forEach(j => {
                        if (e.formId == j) {
                            this.form.formIds.push(e.formId)
                        }
                    })
                })
            })
        },
        submitConfigForm() {
            console.log(this.configObj)
            configCustom(this.configObj).then(res => {
                if (res.data.code == 1) {
                    this.tableData.forEach(e => {
                        if (e.orgId) {
                            if (e.orgId == this.configObj.orgId) {
                                e.bookIds = this.configObj.bookIds
                            }
                        }
                    })
                    this.$message.success("配置成功");
                    this.addPeopleOpen = false;
                    this.configCancel();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        configCancel() {
            this.readBook = false;
            this.configObj.bookIds = [];
            this.configObj.orgId = '';
        },
        configurationRow(index, row) {
            if (row.adminAccount == '') {
                this.$message.error("请填写管理员账号")
                return;
            }
            if (row.orgName == '') {
                this.$message.error("请填写组织名称")
                return;
            }
            this.readBook = true;
            this.configObj.bookIds = row.bookIds;
            this.configObj.orgId = row.orgId;
            console.log(index, row)
        },
        deleteRow(index) {
            console.log(index)
            // if (this.tableData.length == 1) {
            //     this.$message.error("不能全部删除")
            // } else {
            this.tableData.splice(index, 1)
            // }
        },
        deleteAdminRow(index) {
            // if (this.adminList.length == 1) {
            //     this.$message.error("不能全部删除")
            // } else {
            this.adminList.splice(index, 1)
            // }
        },
        deleteViewRow(index) {
            // if (this.viewList.length == 1) {
            //     this.$message.error("不能全部删除")
            // } else {
            this.viewList.splice(index, 1)
            // }
        },
        deleteMemberRow(index) {
            this.memberList.splice(index, 1)
        },
        onAdminAddItem() {
            this.adminList.push({
                username: '',
                managerName: '',
            })
            setTimeout(() => {
                this.$refs.adminList.setScrollTop(1000000);
            })
        },
        onViewAddItem() {
            this.viewList.push({
                username: '',
                managerName: '',
            })
            setTimeout(() => {
                this.$refs.viewList.setScrollTop(1000000);
            })
        },
        onMemberAddItem() {
            this.memberList.push({
                username: '',
                memberName: ''
            })
            setTimeout(() => {
                this.$refs.memberList.setScrollTop(1000000);
            })
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                adminAccount: '',
                orgName: ''
            })
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginDate === '' || !this.form.beginDate) {
                callback(new Error('请选择开始时间'))
            } else if (this.form.endDate === '' || !this.form.endDate) {
                callback(new Error('请选择结束时间'))
            } else {
                callback()
            }
        },
        startDateConfirm(e) {
            if (e > this.form.endDate) {
                this.form.endDate = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginDate) {
                this.form.beginDate = '';
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date())
        },
        enddisabledDate(current) {
            if (this.form.beginDate) {
                return current && current < this.form.beginDate
            } else {
                return current && current < Number(new Date())
            }
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;

            // bookList().then(res => {
            //     this.bookList = res.data.data;
            // });
            this.customList = []

            queryCustoms({ username: this.$storage.get('customId') ? JSON.parse(this.$storage.get('userInfo')).username : '' }).then(res => {
                res.data.data = res.data.data.filter(itemA => {
                    return itemA.customType.includes('030');
                })
                res.data.data.sort(function (a, b) {
                    return a.customName.localeCompare(b.customName);
                })
                res.data.data.forEach(e => {
                    if (e.status == 0) {
                        this.customList.push(e)
                    }
                })
                res.data.data.forEach(e => {
                    if (e.status == 1) {
                        this.customList.push(e)
                    }
                })
            });
        },


        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            this.$refs["form"].validate(valid => {
                if (valid) {
                    this.form.orgList = this.tableData;
                    // this.form.formIds = 
                    // this.form.formIds = this.form.formIdsList
                    this.form.adminList = this.adminList;
                    this.form.viewList = this.viewList;
                    this.form.memberList = this.memberList;

                    console.log(this.form)
                    if (this.$route.query.id) {
                        qunUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                // this.$store.state.tagsView.visitedViews.forEach(e => {
                                //     if (e.path == '/groupSet') {
                                //         this.$store.dispatch('tagsView/delView', e).then(({
                                //             visitedViews
                                //         }) => {
                                //         })
                                //     }
                                // })
                                this.replace('/groupSet', { customId: this.customId, isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        qunCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                // this.$store.state.tagsView.visitedViews.forEach(e => {
                                //     if (e.path == '/groupSet') {
                                //         this.$store.dispatch('tagsView/delView', e).then(({
                                //             visitedViews
                                //         }) => {
                                //         })
                                //     }
                                // })
                                this.replace('/groupSet', { customId: this.customId, isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            this.replace('/groupSet', { customId: this.customId })
            // this.$emit('isTg', true)
        },
        ergodicList(childrenList) {
            if (childrenList.length != 0) {

                childrenList.forEach(e => {
                    e.label = e.orgName
                    e.value = e.orgId

                    console.log(this.findParentNode(e.orgId))
                    if (this.findParentNode(e.orgId).indexOf(2) == -1) {
                        e.disabled = true
                    }
                    if (e.orgType == 5) {
                        // debugger
                        e.disabled = true
                        console.log(e)
                    }
                    if (e.childrenList.length != 0) {
                        e.children = e.childrenList
                        e.children = e.children.filter(itemA => {
                            return itemA.orgType != 5;
                        })
                        this.ergodicList(e.childrenList)
                    }
                })
            }
        },
        // id 指的是当前点击的节点id;
        findParentNode(id) {
            // debugger
            // 初始化所需数据
            var firstParentObj = {}; // 记录直系父级的名称和id即接口要传的数据
            var parentIds = []; // 记录所有的父级ids
            var parentIdsQueue = []; // 记录排队的

            // 将树放到排队系列
            var parentIdsQueue = JSON.parse(JSON.stringify(this.orgList));

            // 开始遍历排队的树
            while (parentIdsQueue.length) {
                //抽取第一个排队的数据 
                let item = parentIdsQueue.shift();

                let { childrenList } = item;
                if (item.orgId === id) {
                    // 第一层就找到了
                    if (!item.parentArr) {
                        firstParentObj = {
                            id: item.orgId,
                            name: item.orgType
                        };
                        parentIds = [item.orgType];
                    } else {
                        // 获取当前节点的parentArr
                        let len = item.parentArr.length;
                        firstParentObj = item.parentArr[len - 1];
                        item.parentArr.forEach(a => {
                            parentIds.push(a.name);
                        });
                        parentIds.push(item.orgType);
                    }

                    // 结束遍历
                    parentIdsQueue = [];

                    break;
                } else if (childrenList && childrenList.length) {
                    let len = childrenList.length;
                    for (let i = len - 1; i >= 0; i--) {
                        // 新建一个数组用于记录它的父亲节点
                        childrenList[i].parentArr = [];

                        // 把它的历史父亲节点们先放入
                        if (item.parentArr) {
                            childrenList[i].parentArr = childrenList[i].parentArr.concat(
                                item.parentArr
                            );
                        }

                        // 再放入当前的父亲节点
                        childrenList[i].parentArr.push({
                            id: item.orgId,
                            name: item.orgType
                        });

                        // 加入到排队序列中
                        parentIdsQueue.unshift(childrenList[i]);
                    }

                }
            }
            return parentIds

        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>