<template>
    <div class="app-container pt0Im pl0Im">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>客户管理</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!customId">新增客户</el-breadcrumb-item>
                <el-breadcrumb-item v-if="customId">编辑客户</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 180px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="客户名称" prop="customName">
                            <el-input @blur="changAbbreviation" v-model="form.customName" placeholder="最多16个字"
                                maxlength="16" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="客户简称" prop="customAbbreviation">
                            <el-input v-model="form.customAbbreviation" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row>
                    <el-col :span="12">
                        <el-form-item label="管理员账号" prop="adminAccount">
                            <el-input @blur="form.adminName = ''" v-model="form.adminAccount" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="管理员姓名" prop="adminName">
                            <el-input v-model="form.adminName" placeholder="最多16个字" maxlength="16" />
                        </el-form-item>
                    </el-col>
                </el-row> -->
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="客户状态" prop="status">
                            <el-radio-group v-model="form.status">
                                <el-radio :label="0">正式客户</el-radio>
                                <el-radio :label="1">演示客户</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row>
                    <el-col :span="24">
                        <el-form-item label="业务类型" prop="customTypeList">
                            <el-checkbox-group v-model="form.customTypeList">
                                <el-checkbox v-for="item in moduleList" :label="item.moduleNo" :key="item.moduleNo">{{
                                    item.moduleName }}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                </el-row> -->
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="管理员" prop="adminList">
                            <el-table ref="adminList" border :data="adminList" style="width: 600px" max-height="250">
                                <el-table-column align="center" type="index" label="序号" width="60" />
                                <el-table-column align="center" prop="adminAccount" label="管理员账号">
                                    <template #default="scope">
                                        <el-input @blur="validateAdminUsername(scope.row)" v-model="scope.row.adminAccount"
                                            placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="adminName" label="管理员姓名">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.adminName" placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" fixed="right" label="操作" width="160">
                                    <template #default="scope">
                                        <el-button link type="primary" size="small"
                                            @click.prevent="deleteAdminRow(scope.$index)">
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div style="width: 100%;">
                                <el-button plain type="primary" class="mt10" style="width: 600px"
                                    @click="onAdminAddItem">增加</el-button>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="业务模块" prop="moduleList" required>
                            <el-table @selection-change="handleFormSelectionChange" ref="moduleList" border
                                :data="form.moduleList" style="width: 100%">
                                <el-table-column align="center" type="selection" width="40" />
                                <el-table-column align="center" prop="moduleName" label="业务名称">
                                    <template #default="scope">
                                        {{ scope.row.moduleName }}
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="authTime" label="授权日期">
                                    <template #default="scope">
                                        <el-date-picker @change="startDateTwoConfirm($event, scope.row)"
                                            :disabled-date="disabledDate" style="width: 100%;" v-model="scope.row.authTime"
                                            type="date" placeholder="开始日期" value-format="x" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="endTime" label="截止日期">
                                    <template #default="scope">
                                        <el-date-picker class="date_picker" @change="endDateTwoConfirm($event, scope.row)"
                                            :disabled-date="disabledDate" style="width: 100%;" v-model="scope.row.endTime"
                                            type="date" placeholder="结束日期" value-format="x" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="resourceNum" label="资源数">
                                    <template #default="scope">
                                        <el-input-number style="width: 100%;" v-model="scope.row.resourceNum" :min="0"
                                            maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="accountNum" label="账号数">
                                    <template #default="scope">
                                        <el-input-number style="width: 100%;" v-model="scope.row.accountNum" :min="0"
                                            maxlength="16" />
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column align="center" fixed="right" label="操作" width="160">
                                    <template #default="scope">
                                        <el-button link type="primary" size="small"
                                            @click.prevent="deleteModuleRow(scope.$index)">
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column> -->
                            </el-table>
                            <!-- <div style="width: 100%;display: flex;justify-content: center;">
                                <el-dropdown size="large">
                                    <el-button plain type="primary" class="mt10">增加</el-button>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item @click="addModuleList(item)" v-for="item in moduleList">{{
                                                item.moduleName }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                            </div> -->
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row style="margin-bottom: 60px;">
                    <el-col :span="24">
                        <el-card class="mr5">
                            <template #header>
                                <div class="card-header">
                                    智能本
                                </div>
                            </template>
                            <el-row>
                                <el-col>
                                    <el-form-item label="" prop="bookIds" label-width="0px">
                                        <el-col class="custom">
                                            <el-link type="primary" @click="openBook" :underline="false">资源配置</el-link>
                                        </el-col>
                                    </el-form-item>
                                    <div>
                                        <div class="fl-warp" style="display: flex;">
                                            <div v-for="item in bookList" class="csbbb"
                                                :style="form.bookIds.indexOf(item.id) != -1 ? '' : 'display:none'">
                                                <el-icon @click="delBookId(item.id)" class="eicon" style="cursor: pointer;">
                                                    <Delete />
                                                </el-icon>
                                                <img style="width: 90px;margin-top: 10px;"
                                                    :src="this.$store.state.zdy.imgUrl + '/book/' + item.id + '/img/cover.png_original'"
                                                    alt="" class="image">
                                                <div style="width: 90px;text-align: center;" class="text-one mb15">{{
                                                    item.bookName }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-col>
                    <!-- <el-col :span="12">
                        <el-card class="mr5">
                            <template #header>
                                <div class="card-header">
                                    群组配置
                                </div>
                            </template>
                            <div>
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="业务类型" prop="businessTypeList" label-width="80px">
                                            <el-checkbox-group v-model="form.businessTypeList">
                                                <el-checkbox label="0" key="0">党建</el-checkbox>
                                                <el-checkbox label="1" key="1">教研</el-checkbox>
                                                <el-checkbox label="2" key="2">作业</el-checkbox>
                                            </el-checkbox-group>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col>
                                        <el-form-item label="服务日期" prop="serviceTime" label-width="80px">
                                            <el-col :span="11" class="date_picker">
                                                <el-date-picker @change="startDateConfirm" :disabled-date="disabledDate"
                                                    style="width: 100%;" v-model="form.beginDate" type="date"
                                                    placeholder="开始日期" value-format="x" />
                                            </el-col>
                                            <el-col :span="2" class="text-center">
                                                <span class="text-gray-500">至</span>
                                            </el-col>
                                            <el-col :span="11">
                                                <el-date-picker class="date_picker" @change="endDateConfirm"
                                                    :disabled-date="enddisabledDate" style="width: 100%;"
                                                    v-model="form.endDate" type="date" placeholder="结束日期"
                                                    value-format="x" />
                                            </el-col>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col>
                                        <el-form-item label="组织数" prop="maxOrgCount" label-width="80px">
                                            <el-input-number v-model="form.maxOrgCount" precision="0" :min="1"
                                                :max="1000" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col>
                                        <el-form-item label="群组数" prop="maxGroupCount" label-width="80px">
                                            <el-input-number v-model="form.maxGroupCount" precision="0" :min="1"
                                                :max="1000" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col>
                                        <el-form-item label="资源库" prop="bookIds" label-width="80px">
                                            <el-col class="custom">
                                                <el-link type="primary" @click="openBook" :underline="false">资源配置</el-link>
                                            </el-col>
                                        </el-form-item>
                                        <div>
                                            <div class="fl-warp" style="display: flex;max-height: 300px;overflow-y: auto;">
                                                <div v-for="item in bookList" class="csbbb"
                                                    :style="form.bookIds.indexOf(item.id) != -1 ? '' : 'display:none'">
                                                    <el-icon @click="delBookId(item.id)" class="eicon"
                                                        style="cursor: pointer;">
                                                        <Delete />
                                                    </el-icon>
                                                    <img style="width: 90px;margin-top: 10px;"
                                                        :src="this.$store.state.zdy.imgUrl+'/book/' + item.id + '/img/cover.png_original'"
                                                        alt="" class="image">
                                                    <div style="width: 90px;text-align: center;" class="text-one mb15">{{
                                                        item.bookName }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="12">
                        <el-card class="ml5">
                            <template #header>
                                <div class="card-header">
                                    表单配置
                                </div>
                            </template>
                            <div>
                                <el-row>
                                    <el-col>
                                        <el-form-item label="表单管理授权日期" prop="formTime">
                                            <el-col :span="11" class="date_picker">
                                                <el-date-picker @change="startFormDateConfirm" :disabled-date="disabledDate"
                                                    style="width: 100%;" v-model="form.formBeginDate" type="date"
                                                    placeholder="开始日期" value-format="x" />
                                            </el-col>
                                            <el-col :span="2" class="text-center">
                                                <span class="text-gray-500">至</span>
                                            </el-col>
                                            <el-col :span="11">
                                                <el-date-picker class="date_picker" @change="endFormDateConfirm"
                                                    :disabled-date="enddisabledFormDate" style="width: 100%;"
                                                    v-model="form.formEndDate" type="date" placeholder="结束日期"
                                                    value-format="x" />
                                            </el-col>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col>
                                        <el-form-item label="点阵资源授权数" prop="dotAuthCount">
                                            <el-input-number v-model="form.dotAuthCount" precision="0" :min="1"
                                                :max="1000" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col>
                                        <el-form-item label="采集端授权数" prop="collectPartCount">
                                            <el-input-number v-model="form.collectPartCount" precision="0" :min="1"
                                                :max="1000" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-card>
                    </el-col> -->
                </el-row>

                <!-- <el-row>
                    <el-col>
                        <el-form-item label="组织配置" prop="orgList">
                            <el-table border :data="tableData" style="width: 100%" max-height="250">
                                <el-table-column align="center" prop="adminAccount" label="管理员账号">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.adminAccount" placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="adminName" label="管理员姓名">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.adminName" placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" prop="orgName" label="组织名称">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.orgName" placeholder="最多16个字" maxlength="16" />
                                    </template>
                                </el-table-column>
                                <el-table-column align="center" fixed="right" label="操作" width="160">
                                    <template #default="scope">
                                        <el-button link type="primary" size="small"
                                            @click.prevent="deleteRow(scope.$index)">
                                            删除
                                        </el-button>
                                        <el-button v-if="customId && scope.row.orgId" link type="primary" size="small"
                                            @click.prevent="configurationRow(scope.$index, scope.row)">
                                            配置
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-button plain type="primary" class="mt10" style="width: 100%"
                                @click="onAddItem">增加</el-button>
                        </el-form-item>
                    </el-col>
                </el-row> -->
            </el-form>
        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:64px;' : 'left:201px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
        <el-dialog :title="'配置云本'" v-model="chooseBook" width="670px">
            <el-col class="custom ylsb" style="">
                <div v-for="item in bookList" :style="form.bookIds.indexOf(item.id) != -1 ? 'display:none' : ''">
                    <div class="ylqs" @click="setChooseBook(item.id)"
                        :style="chooseBookIds.indexOf(item.id) != -1 ? 'background: #a3d6ff' : ''">
                        <img style="width: 100px;margin-top: 10px;"
                            :src="this.$store.state.zdy.imgUrl + '/book/' + item.id + '/img/cover.png_original'" alt=""
                            class="image">
                        <div style="width: 100px;" class="text-one mb15">{{ item.bookName }}</div>
                    </div>
                </div>
            </el-col>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitChooseBookForm">确 定</el-button>
                    <el-button @click="chooseBookCancel">取 消</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { bookList } from "@/api/adminservice/public"
import { bookManageList } from "@/api/adminservice/bookManage"
import { createCustoms, custominfo, updateCustoms, configCustom, moduleList } from "@/api/adminservice/custom"
import { qunQueryUser } from "@/api/adminservice/qun"
import { timestampToTime } from "@/utils/date";

export default {
    name: "demoAdd",
    components: {
    },
    data() {
        return {
            adminList: [],
            chooseBook: false,
            customId: '',
            readBook: false,
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            moduleList: [],
            formSelectList: [],
            // 表单参数
            form: {
                status: 0,
                bookIds: [],
                customTypeList: [],
                businessTypeList: [],
                moduleList: [],
                maxOrgCount: 100,
                maxGroupCount: 100,
                dotAuthCount: 1,
                collectPartCount: 100,
                beginDate: '',
                endDate: '',
                formBeginDate: '',
                formEndDate: '',
            },
            tableData: [{
                adminAccount: '',
                adminName: '',
                orgName: ''
            }],
            // 表单校验
            rules: {
                customName: [
                    { required: true, message: "请输入客户名称", trigger: "blur" },
                ],
                customAbbreviation: [
                    { required: true, message: "请输入客户简称", trigger: "blur" },
                ],
                adminAccount: [
                    { required: true, message: "请输入管理员账号", trigger: "blur" },
                    { validator: this.validateUsername, trigger: "blur" }
                ],
                adminName: [
                    { required: true, message: "请输入管理员姓名", trigger: "blur" },
                ],
                adminList: [
                    { required: true, validator: this.checkAdminValidator }
                ],
                // maxGroupCount: [
                //     { required: true, message: "请输入最大群组数", trigger: "blur" },
                // ],
                // maxOrgCount: [
                //     { required: true, message: "请输入最大组织数", trigger: "blur" },
                // ],
                // dotAuthCount: [
                //     { required: true, message: "请输入点阵资源授权数", trigger: "blur" },
                // ],
                // collectPartCount: [
                //     { required: true, message: "请输入采集端授权数", trigger: "blur" },
                // ],
                // serviceTime: [
                //     { required: true, validator: this.checkValidator }
                // ],
                // formTime: [
                //     { required: true, validator: this.checkFormValidator }
                // ],
                bookIds: [
                    // { required: true, validator: this.checBookValidator },
                ],
                status: [
                    { required: true, message: "请选择客户状态", trigger: "change" },
                ],
                customTypeList: [
                    { required: true, message: "请选择客户类型", trigger: "change" },
                ],
                // businessTypeList: [
                //     { required: true, message: "请选择业务类型", trigger: "change" },
                // ]
                // orgList: [
                //     { required: true, validator: this.checkOrgValidator }
                // ]
            },
            bookList: [],
            configObj: {
                bookIds: [],
                orgId: ''
            },
            chooseBookIds: [],
        };
    },
    created() {
        // this.getModuleList();
    },
    mounted() {
        if (this.$route.query.customId) {
            this.customId = this.$route.query.customId;
            this.getList();
            moduleList().then(res => {
                this.moduleList = res.data.data;
                custominfo({ customId: this.customId }).then(res => {
                    this.form = res.data.data;
                    this.form.customTypeList = res.data.data.customType && res.data.data.customType.split(',')

                    this.form.businessTypeList = res.data.data.businessType && res.data.data.businessType.split(',')
                    this.tableData = res.data.data.orgList;
                    this.adminList = res.data.data.adminList;
                    console.log(this.form.moduleList);
                    this.moduleList.forEach(e => {
                        if (this.form.moduleList) {
                            this.form.moduleList.forEach(j => {
                                if (e.moduleNo == j.moduleNo) {
                                    e.authTime = j.authTime;
                                    e.accountNum = j.accountNum;
                                    e.createTime = j.createTime;
                                    e.customId = j.customId;
                                    e.endTime = j.endTime;
                                    e.resourceNum = j.resourceNum;
                                }
                            })
                        }
                    })
                    console.log(this.moduleList);

                    this.form.moduleList = this.moduleList;
                    console.log(this.form.moduleList);
                    setTimeout(() => {
                        this.form.moduleList.forEach((e, i) => {
                            this.form.customTypeList.forEach((j, k) => {
                                if (e.moduleNo == j) {
                                    console.log(e.moduleNo, j);
                                    this.$refs.moduleList.toggleRowSelection(this.form.moduleList[i], true);
                                }
                            })
                        })
                    })

                    // this.bookList = res.data.data.bookList;
                })
            });

        } else {
            moduleList().then(res => {
                this.moduleList = res.data.data;
                this.moduleList.forEach(e => {
                    this.form.moduleList.push(e)
                })
            });
            // this.form.beginDate = Number(new Date())
            // this.form.endDate = Number(new Date()) + 31104000000

            this.getList();
        }

    },
    watch: {

    },
    computed: {
    },
    methods: {
        changAbbreviation() {
            if (!this.form.customAbbreviation) {
                this.form.customAbbreviation = this.form.customName
            }
        },
        addModuleList(item) {
            console.log(item);
            this.form.moduleList.push(item)
        },
        getModuleList() {
            moduleList().then(res => {
                this.moduleList = res.data.data;
            });
        },
        checkAdminValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.adminList.length; i++) {
                var e = this.adminList[i];
                if (e.adminAccount == '' || e.adminName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        validateAdminUsername(value) {
            qunQueryUser({ mobile: value.adminAccount }).then(res => {
                const result = res.data.data
                if (!result) {
                    value.adminAccount = ''
                    value.adminName = ''
                    this.$message.error("请输入正确账号")
                } else {
                    value.adminName = res.data.data.nickName
                    value.adminUserId = res.data.data.userId
                }
            })
        },
        deleteAdminRow(index) {
            this.adminList.splice(index, 1)
        },
        deleteModuleRow(index) {
            this.form.moduleList.splice(index, 1)
        },
        onAdminAddItem() {
            this.adminList.push({
                adminAccount: '',
                adminName: '',
            })
        },
        handleFormSelectionChange(val) {
            console.log(val)
            console.log(86400000 * 1826);
            var jlModu = []
            val.forEach(e => {
                jlModu.push(e.moduleNo)
            })
            this.form.moduleList.forEach(j => {
                if (jlModu.includes(j.moduleNo)) {
                    if (!j.authTime) {
                        j.authTime = new Date().getTime()
                        j.endTime = new Date().getTime() + 86400000 * 1826;
                    }
                } else {
                    j.authTime = ''
                    j.endTime = ''
                }
            })
            this.formSelectList = val;
        },
        changeBd() {
            if (this.form.customTypeList.indexOf('1') != -1) {
                this.form.formBeginDate = new Date().getTime()
                this.form.formEndDate = new Date().getTime() + 364 * 24 * 60 * 60 * 1000
            } else {
                this.form.formBeginDate = ''
                this.form.formEndDate = ''
            }
        },
        changeQbj() {
            if (this.form.customTypeList.indexOf('2') != -1) {
                this.form.beginDate = new Date().getTime()
                this.form.endDate = new Date().getTime() + 364 * 24 * 60 * 60 * 1000
            } else {
                this.form.beginDate = ''
                this.form.endDate = ''
            }
        },
        delBookId(id) {
            this.form.bookIds.splice(this.form.bookIds.indexOf(id), 1)
        },
        setChooseBook(id) {
            if (this.chooseBookIds.indexOf(id) === -1) {
                this.chooseBookIds.push(id)
            } else {
                this.chooseBookIds.splice(this.chooseBookIds.indexOf(id), 1)
            }
        },
        chooseBookCancel() {
            this.chooseBook = false;
        },
        submitChooseBookForm() {
            this.form.bookIds = this.form.bookIds.concat(this.chooseBookIds)
            this.chooseBook = false;
        },
        openBook() {
            this.chooseBookIds = [];
            this.chooseBook = true;
        },
        validateUsername(rule, value, callback) {
            qunQueryUser({ mobile: value }).then(res => {
                const result = res.data.data
                if (!result) {
                    callback(new Error("该账号用户不存在"))
                } else {
                    if (this.form.adminName == '') {
                        this.form.adminName = res.data.data.nickName;
                    }

                    callback()
                }
            })
        },
        submitConfigForm() {
            console.log(this.configObj)
            configCustom(this.configObj).then(res => {
                if (res.data.code == 1) {
                    this.tableData.forEach(e => {
                        if (e.orgId) {
                            if (e.orgId == this.configObj.orgId) {
                                e.bookIds = this.configObj.bookIds
                            }
                        }
                    })
                    this.$message.success("配置成功");
                    this.addPeopleOpen = false;
                    this.configCancel();
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        configCancel() {
            this.readBook = false;
            this.configObj.bookIds = [];
            this.configObj.orgId = '';
        },
        configurationRow(index, row) {
            if (row.adminAccount == '') {
                this.$message.error("请填写管理员账号")
                return;
            }
            if (row.adminName == '') {
                this.$message.error("请填写管理员姓名")
                return;
            }
            if (row.orgName == '') {
                this.$message.error("请填写组织名称")
                return;
            }
            this.readBook = true;
            this.configObj.bookIds = row.bookIds;
            this.configObj.orgId = row.orgId;
            console.log(index, row)
        },
        deleteRow(index) {
            console.log(index)
            if (this.tableData.length == 1) {
                this.$message.error("不能全部删除")
            } else {
                this.tableData.splice(index, 1)
            }
        },
        onAddItem() {
            console.log(this.tableData)
            this.tableData.push({
                adminAccount: '',
                adminName: '',
                orgName: ''
            })
        },
        checBookValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.bookIds.length == 0) {
                callback(new Error('请选择资源库书本'))
            } else {
                callback()
            }
        },
        checkFormValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.formBeginDate === '' || !this.form.formBeginDate) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.formEndDate === '' || !this.form.formEndDate) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginDate === '' || !this.form.beginDate) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endDate === '' || !this.form.endDate) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        checkOrgValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            var result = true;
            for (var i = 0; i < this.tableData.length; i++) {
                var e = this.tableData[i];
                if (e.adminAccount == '' || e.adminName == '' || e.orgName == '') {
                    result = false
                    break;
                }
            }
            if (result) {
                callback()
            } else {
                callback(new Error('请填写完整信息'))
            }
        },
        startDateConfirm(e) {
            if (e > this.form.endDate) {
                this.form.endDate = '';
            }
        },
        startFormDateConfirm(e) {
            if (e > this.form.formEndDate) {
                this.form.formEndDate = '';
            }
        },
        startDateTwoConfirm(e, row) {
            if (e > row.endTime) {
                row.endTime = '';
            }
        },
        endDateTwoConfirm(e, row) {
            console.log(e, row)
            if (e < row.authTime) {
                row.authTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginDate) {
                this.form.beginDate = '';
            }
        },
        endFormDateConfirm(e) {
            console.log(e,)
            if (e < this.form.formBeginDate) {
                this.form.formBeginDate = '';
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginDate) {
                return current && current < this.form.beginDate
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        enddisabledFormDate(current) {
            if (this.form.formBeginDate) {
                return current && current < this.form.formBeginDate
            } else {
                return current && current < Number(new Date())
            }
        },
        /** 查询基础：基数数据列表 */
        getList() {
            this.loading = false;
            bookManageList().then(res => {
                this.bookList = res.data.data;
            });
        },


        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            console.log(this.formSelectList);
            this.$refs["form"].validate(valid => {
                if (valid) {
                    var obj = JSON.parse(JSON.stringify(this.form))
                    var tscustomType = []
                    var result = true;
                    if (this.formSelectList.length == 0) {
                        this.$message.error('业务模块必须勾选一个')
                        return
                    }
                    for (var i = 0; i < this.formSelectList.length; i++) {
                        var e = this.formSelectList[i];
                        tscustomType.push(e.moduleNo)
                        if (!e.authTime) {
                            this.$message.error(e.moduleName + '授权日期未填写')
                            result = false
                        }
                        if (!e.endTime) {
                            this.$message.error(e.moduleName + '截止日期未填写')
                            result = false
                        }
                    }
                    for (var i = 0; i < obj.moduleList.length; i++) {
                        var e = obj.moduleList[i];
                        if (tscustomType.indexOf(e.moduleNo) >= 0) {

                        } else {
                            obj.moduleList.splice(i, 1)
                            i--;
                        }
                    }
                    if (result) {

                    } else {
                        return
                    }
                    obj.adminList = this.adminList;
                    // this.form.orgList = this.tableData;
                    obj.customType = tscustomType.join(",")
                    obj.businessType = obj.businessTypeList && obj.businessTypeList.join(',')
                    console.log(obj)
                    if (this.customId) {
                        updateCustoms(obj).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/customerManage', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        });
                    } else {
                        createCustoms(obj).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/customerManage', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            }).catch(e => {
                console.log(e);
            })
        },

        // 取消按钮
        cancel() {
            this.replace('/customerManage')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.custom /deep/ .el-checkbox__input {
    position: absolute;
    bottom: 0;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    /* height: 270px; */
    /* overflow-y: auto; */
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>